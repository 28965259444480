<template>
    <div>
        <div v-if="!statusEdit && !editStaffReferral && !editStaffTracking">
            <!-- btn form repeater office -->
            <b-button
                variant="primary"
                class="float-right mb-1"
                style="padding: 8px;"
                @click="isAddNewUserSidebarActive = true"
                v-if="isAdmin || programClinician === 'PGRC - Group Practice' || programClinician === 'PGRC - OASAS Program'"
            >
                <feather-icon
                    icon="PlusIcon"
                    size="16"
                />
                Add Clinician Staff
            </b-button>
            <add-new-clinician-staff
                :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
            />
            <h2 class="mb-1">{{arrayCliniciansStaff.length}} <span style="font-size: 14px; color: #000;"> results found</span></h2>
            <b-table
                :items="arrayCliniciansStaff"
                :fields="adminOrManageUsers ? fieldsTableAdmin : fieldsTable"
                responsive="sm"
                :busy="isBusy"
                show-empty
                empty-text="No records found"
                empty-filtered-text="No results match"
                hover
                @row-clicked="logidusuario"
            >
                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-1">Loading...</strong>
                    </div>
                </template>

                <template #cell(clinicianStaffId)="data">
                    #{{data.item.clinicianStaffId}}
                </template>

                <template #cell(actions)="data">
                    <b-button
                        v-if="adminOrManageUsers || isClinician"
                        variant="primary"
                        class="btn-icon mr-50"
                        v-b-tooltip.hover.top="'Referral Details'"
                        @click.prevent="getReferralStaff(data.item.id)"
                    >
                        <feather-icon icon="CheckSquareIcon" />
                    </b-button>
                    <b-button
                        v-if="isAdmin"
                        variant="primary"
                        class="btn-icon mr-50"
                        v-b-tooltip.hover.top="'Tracking Details'"
                        @click.prevent="getTrackingStaff(data.item.id)"
                    >
                        <feather-icon icon="AlignLeftIcon" />
                    </b-button>
                    <b-button
                        v-if="isAdmin"
                        variant="primary"
                        class="btn-icon"
                        v-b-tooltip.hover.top="'Delete Clinician'"
                        @click.prevent="deleteClinicianStaff(data.item.id)"
                    >
                        <feather-icon icon="Trash2Icon" />
                    </b-button>
                </template>
            </b-table>
        </div>
        
        <!-- Edit clinician staff -->
        <div v-else-if="statusEdit">
            <edit-clinician-staff :idClinicianStaff="idClinicianStaff" @statusEditCLinicianStaff="statusEdit = $event" @pushEditButton="pushEditButton = $event" />
        </div>

        <edit-clinician-staff-referral-details :edit-staff-referral.sync="editStaffReferral" :idClinicianStaff="idClinicianStaff" v-if="editStaffReferral && (isAdmin)" />
        <!-- <edit-clinician-staff-tracking-details :edit-staff-tracking.sync="editStaffTracking" :idClinicianStaff="idClinicianStaff" v-if="editStaffTracking && (isAdmin)" /> -->
    </div>
</template>

<script>
import { 
    BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTable, BFormInvalidFeedback, BModal, VBModal, BAlert, BFormSelect,
    BSpinner, VBTooltip,
} from 'bootstrap-vue'
import EditClinicianStaff from './components/EditClinicianStaff.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { heightTransition } from '@core/mixins/ui/transition'
import flatPickr from 'vue-flatpickr-component'
import { required, email } from '@validations'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import AddNewClinicianStaff from './AddNewClinicianStaff.vue'
import EditClinicianStaffReferralDetails from './EditClinicianStaffReferralDetails.vue'
import EditClinicianStaffTrackingDetails from './EditClinicianStaffTrackingDetails.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import { db, auth } from '@/firebase'
import moment from 'moment'
import parse from 'uuid/dist/parse'

export default {
    data() {
        return {
            // validation
            required,
            email,
            options: {
                block: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'false',
                    delimiter: ''
                },
            },
            userData: '',
            isClinician: false,
            clinicianOrClinicianViewer: false,
            dataSpecialist: false,
            adminOrManageUsers: false,
            isAdmin: false,
            msgRemoteSessions: '',
            statusEditClinician: false,
            isAddNewUserSidebarActive: false,
            programClinician: '',
            idClinicianStaff: '',
            pushEditButton: false,
            editStaffReferral: false,
            editStaffTracking: false,

            // filters
            clientClaimsName: '',
            clientClaimsStatus: null,
            optionsClaimsClientStatus: [
                {value: null, text: 'Approval status'},
                {value: 'paid', text: 'Paid'},
                {value: 'approved', text: 'Approved'},
                {value: 'denied', text: 'Denied'},
                {value: 'pending dm approval', text: 'Pending DM Approval'},
                {value: 'pending pm approval', text: 'Pending PM Approval'},
            ],
            
            // view edit
            statusEdit: false,
            idClientClaim: '',

            // settings table and edit view
            statusCreate: false,
            arrayCliniciansStaff: [],
            fieldsTable: [
                { key: 'clinicianStaffId', sortable: false },
                { key: 'clinicianName', sortable: false },
                { key: 'clinicianEmail', sortable: false },
                { key: 'actions', sortable: false },
            ],
            fieldsTableAdmin: [
                { key: 'clinicianStaffId', sortable: false },
                { key: 'clinicianName', sortable: false },
                { key: 'clinicianEmail', sortable: false },
                { key: 'actions', sortable: false },
            ],

            // overlay
            isBusy: true,
            validFormRepeater: true,
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTable,
        BFormInvalidFeedback,
        BModal,
        BAlert,
        BFormSelect,
        BSpinner,
        Cleave,
        
        // select
        vSelect,

        // date
        flatPickr,

        // Form Validation
        ValidationProvider,
        ValidationObserver,

        // edit claim
        EditClinicianStaff,
        AddNewClinicianStaff,
        EditClinicianStaffReferralDetails,
        EditClinicianStaffTrackingDetails,
    },
    directives: {
        'b-tooltip': VBTooltip,
        'b-modal': VBModal,
        Ripple,
    },
    mixins: [heightTransition],
    computed: {
        ...mapGetters([]),
        ...mapState(['usuario'])
    },
    props: ['changeStatusEditClinician'],
    watch: {
        statusEdit(val) {
            // console.log(val)
            if(val) {
                this.statusEditClinician = true
                this.$emit('statusEditClinician',true)
            } else {
                this.statusEditClinician = false
                this.$emit('statusEditClinician',false)
            }
        },
        statusCreate(val) {
            if(val) {
                // this.statusEditClinician = true
                this.$emit('statusEditClinician',true)
            } else {
                // this.statusEditClinician = false
                this.$emit('statusEditClinician',false)
            }
        },
        changeStatusEditClinician(val) {
            if(val) {
                if(this.statusCreate) {
                    this.statusCreate = true
                } else {
                    this.statusEditClinician = true
                    // this.statusEdit = true
                    this.$emit('statusEditClinician',true)
                }
            } else {
                // console.log(val)
                this.statusEditClinician = false
                this.statusEdit = false
                this.statusCreate = false
                this.$emit('statusEditClinician',false)
            }
        }
    },
    methods: {
        ...mapActions([]),
        getReferralStaff(item) {
            this.idClinicianStaff = item
            this.editStaffReferral = true
        },
        getTrackingStaff(item) {
            this.idClinicianStaff = item
            this.editStaffTracking = true
        },
        logidusuario(items) {
            this.idClinicianStaff = items.id
            this.statusEdit = true
        },
        deleteClinicianStaff(payload) {
            this.$swal({
                title: 'Remove',
                text: "Are you sure you want to delete this clinician staff?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1 cancelBtn-modal',
                },
                buttonsStyling: false,
            }).then(result => {
                if(result.value) {
                    db.collection('usuarios').doc(payload).delete()
                    .then(() => {
                        console.log('deleted clinician')
                    })
                }
            })
        }
    },
    created() {
        this.arrayCliniciansStaff = []
        this.isAddNewUserSidebarActive = false
        this.statusEdit = false
        this.editStaffTracking = false
        this.editStaffReferral = false
        // window.addEventListener('resize', this.initTrHeight)

        const user = auth.currentUser
        db.collection('usuarios').doc(user.uid).get()
        .then(userDoc => {
            if(userDoc.data().role === 'admin') {
                this.adminOrManageUsers = true
                this.isAdmin = true

                db.collection('usuarios').where('role', '==', 'clinician-staff').where('dependency', '==', this.$route.params.id)
                .onSnapshot(usersDocs => {
                    this.arrayCliniciansStaff = []
                    this.isBusy = true
                    usersDocs.forEach(doc => {
                        // console.log(doc.data())
                        this.arrayCliniciansStaff.push({
                            id: doc.id,
                            clinicianStaffId: doc.data().codigo,
                            clinicianName: doc.data().nombre ? doc.data().nombre : doc.data().firstName,
                            clinicianEmail: doc.data().email,
                        })
                    })
                    this.isBusy = false
                })
            } else if(userDoc.data().role === 'staff') {
                if(userDoc.data().permissions.includes('manage users')) {
                    this.adminOrManageUsers = true
                } else if(userDoc.data().permissions.includes('data specialist')) {
                    this.dataSpecialist = true
                } else if(userDoc.data().permissions.includes('clinician viewer')) {
                    this.clinicianOrClinicianViewer = true
                } else {
                    this.clinicianOrClinicianViewer = true
                }
            } else if(userDoc.data().role === 'clinician') {
                this.isClinician = true
                this.programClinician = userDoc.data().program ? userDoc.data().program : ''
            }
        })

        db.collection('usuarios').where('role', '==', 'clinician-staff').where('dependency', '==', this.$route.params.id)
        .onSnapshot(usersDocs => {
            if(!this.isAdmin) {
                this.arrayCliniciansStaff = []
                this.isBusy = true
                usersDocs.forEach(doc => {
                    // console.log(doc.data())
                    this.arrayCliniciansStaff.push({
                        id: doc.id,
                        clinicianStaffId: doc.data().codigo,
                        clinicianName: doc.data().nombre ? doc.data().nombre : doc.data().firstName,
                        clinicianEmail: doc.data().email,
                    })
                })
                this.isBusy = false
            }
        })

        db.collection('usuarios').doc(this.$route.params.id).get()
        .then(userDoc => {
            this.userData = {}
            this.userData = {
                codigo: userDoc.data().codigo ? userDoc.data().codigo : '',
                firstName: userDoc.data().firstName ? userDoc.data().firstName : '',
                lastName: userDoc.data().lastName ? userDoc.data().lastName : '',
                agency: userDoc.data().agency ? userDoc.data().agency : '',
                region: userDoc.data().region ? userDoc.data().region : '',
                borough: userDoc.data().borough ? userDoc.data().borough : '',
                statusReferral: userDoc.data().statusReferral ? userDoc.data().statusReferral : '',
                program: userDoc.data().program ? userDoc.data().program : '',
                email: userDoc.data().email ? userDoc.data().email : '',
                uid: userDoc.data().uid ? userDoc.data().uid : '',
                id: userDoc.id
            }
        })

    },
    mounted() {
        // this.initTrHeight()

        if (window.history && window.history.pushState) {
            window.history.pushState('forward', null, '');

            window.addEventListener('popstate', () => {
                // alert('Back button was pressed.');
                this.statusEdit = false
                this.statusCreate = false
                this.$emit('statusEditClinician',false)
            });
        }

        this.$emit('statusEditClinician',true)
    },
    destroyed() {
        // window.removeEventListener('resize', this.initTrHeight)
    },
}
</script>

<style>
.vs--disabled .vs__selected {
    color: #656b85 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
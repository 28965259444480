<template>
    <div>
        <validation-observer ref="addClinicianRules">
            <b-form
                class="px-2"
            >
                <b-row class="mb-3">
                    <b-col md="8">
                        <!-- Profile -->
                        <h4>Profile</h4>
                        <hr>
                        <b-row>
                            <b-col md="6">
                                <!-- First Name -->
                                <b-form-group
                                    label-for="First name"
                                >
                                <template v-slot:label>
                                    First name <span class="text-danger">*</span>
                                </template>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="First name"
                                        rules="required"
                                    >
                                        <b-form-input
                                            id="full-name"
                                            v-model="firstName"
                                            trim
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="First name"
                                            :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col md="6">
                                <!-- Last Name -->
                                <b-form-group
                                    label-for="Last name"
                                >
                                <template v-slot:label>
                                    Last name <span class="text-danger">*</span>
                                </template>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Last name"
                                        rules="required"
                                    >
                                        <b-form-input
                                            id="full-name"
                                            v-model="lastName"
                                            trim
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Last name"
                                            :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-col md="6" class="pl-0">
                            <!-- Email -->
                            <b-form-group
                                label-for="email"
                            >
                            <template v-slot:label>
                                Email <span class="text-danger">*</span>
                            </template>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="email"
                                        v-model="email"
                                        :state="errors.length > 0 ? false:null"
                                        trim
                                        placeholder="Email"
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col md="6" class="pl-0" v-if="!dataSpecialist">
                            <b-form-group
                                label-for="Active Date"
                            >
                            <template v-slot:label>
                                Active Date <span class="text-danger">*</span>
                            </template>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Active Date"
                                    rules="required"
                                >
                                    <flat-pickr
                                        v-model="activeDate"
                                        class="form-control"
                                        placeholder="Select a Date"
                                        :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group
                                label-for="Expiration Date"
                            >
                            <template v-slot:label>
                                Expiration Date <span class="text-danger">*</span>
                            </template>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Expiration Date"
                                    rules="required"
                                >
                                    <flat-pickr
                                        v-model="expirationDate"
                                        class="form-control"
                                        placeholder="Select a Date"
                                        :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Details -->
                        <h4 class="mt-2">Details</h4>
                        <hr>
                        <b-row cols="12">
                            <b-col md="6">
                                <!-- License type -->
                                <b-form-group
                                    label="License Type"
                                    label-for="licenseType"
                                >
                                    <v-select
                                        v-model="licenseType"
                                        placeholder="License type"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="optionsLicenseType"
                                        multiple
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <!-- Licese number -->
                                <b-form-group
                                    label="License Number"
                                    label-for="License Number"
                                >
                                    <b-form-input
                                        v-model="licenseNumber"
                                        trim
                                        placeholder="License number"
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <b-col md="6" class="pl-0">
                            <!-- Insurance types accepted -->
                            <b-form-group
                                label="Insurance types accepted"
                                label-for="Insurance types accepted"
                            >
                                <v-select
                                    v-model="insuranceTypesAccepted"
                                    placeholder="Insurance types accepted"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="optionsInsuranceTypesAccepted"
                                    multiple
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col md="6" class="pl-0">
                            <!-- Specialities -->
                            <b-form-group
                                label="Specialities"
                                label-for="Specialities"
                            >
                                <v-select
                                    v-model="specialities"
                                    placeholder="Specialities"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="optionsSpecialities"
                                    multiple
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col md="6" class="pl-0">
                            <!-- Languages -->
                            <b-form-group
                                label="Languages"
                                label-for="Languages"
                            >
                                <v-select
                                    v-model="languages"
                                    placeholder="Languages"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="optionsLanguages"
                                    multiple
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col md="6" class="pl-0">
                            <!-- Mailing address -->
                            <b-form-group
                                label="Mailing address"
                                label-for="Mailing address"
                            >
                                <b-form-input
                                    v-model="mailingAddress"
                                    trim
                                    placeholder="Mailing address"
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                            </b-form-group>
                        </b-col>

                        <b-row cols="12">
                            <b-col md="4">
                                <!-- City -->
                                <b-form-group
                                    label="City"
                                    label-for="City"
                                >
                                    <b-form-input
                                        v-model="city"
                                        trim
                                        placeholder="City"
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <!-- State -->
                                <b-form-group
                                    label="State"
                                    label-for="State"
                                >
                                    <b-form-input
                                        v-model="state"
                                        trim
                                        placeholder="State"
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <!-- zip -->
                                <b-form-group
                                    label="ZIP"
                                    label-for="ZIP"
                                >
                                    <b-form-input
                                        v-model="zip"
                                        trim
                                        placeholder="ZIP"
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-col md="6" class="pl-0">
                            <!-- Phone -->
                            <b-form-group
                                label="Phone"
                                label-for="phone"
                            >
                                <b-form-input
                                    id="phone"
                                    v-model="contact"
                                    trim
                                    placeholder="Phone"
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                            </b-form-group>
                        </b-col>

                        <h4 class="mt-2">Notes</h4>
                        <hr>
                        <b-col md="6" class="pl-0">
                            <!-- Notes -->
                            <b-form-group
                                label="Notes"
                                label-for="notes"
                            >
                                <b-form-textarea
                                    v-model="notes"
                                    placeholder="Notes"
                                    rows="3"
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                            </b-form-group>
                        </b-col>
                    </b-col>

                    <!-- Form Actions -->
                    <b-col md="10" v-if="!clinicianOrClinicianViewer && !dataSpecialist"></b-col>
                    <b-col md="2" v-if="!clinicianOrClinicianViewer && !dataSpecialist">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                            @click.prevent="validationForm"
                        >
                        Save
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BTabs, BTab, BCardText, BCard, BCol, BRow, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, email, min, confirmed } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import moment from 'moment'
import { mapActions } from 'vuex'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            min,
            confirmed,
            countries,
            userData: '',
            idClinicianDependency: '',

            // profile
            firstName: '',
            lastName: '',
            agency: '',
            email: '',
            countryOfResidence: '',
            optionsCountryOfResidence: [],
            region: '',
            optionsRegion: [],
            borough: '',
            optionsBorough: [
                'Bronx',
                'Brooklyn',
                'Manhattan',
                'Queens',
                'Staten Island'
            ],
            program: '',
            optionsProgram: [],
            activeDate: null,
            expirationDate: null,

            // program OASAS
            programType: '',
            optionsProgramType: [
                'Outpatient Treatment Services',
                'ATCs (OASAS run)',
                'Chemical Dependency Youth',
                'Crisis Services',
                'Inpatient Treatment Services',
                'Methadone Treatment',
                'Residential Treatment Services'
            ],

            // details
            licenseType: '',
            optionsLicenseType: [],
            licenseNumber: '',
            insuranceTypesAccepted: '',
            optionsInsuranceTypesAccepted: [],
            specialities: '',
            optionsSpecialities: [],
            languages: '',
            optionsLanguages: [],
            mailingAddress: '',
            city: '',
            state: '',
            zip: '',
            contact: '',

            // password
            passValue: '',
            passConfirm: '',

            // Attributes
            attributes: '',
            optionsAttributes: [],
            providesRemoteAndOrOffSiteSessionsIfRequired: false,

            // notes
            notes: '',

            // change email
            emailOld: '',
            
            rules: {
                required: v => !!v || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                email: v => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(v) || 'Email not valid.'
                },
            },
            statusReferral: '',
            clinicianOrClinicianViewer: false,
            clinicianUsers: false,
            dataSpecialist: false,
            adminOrManageUsers: false,
            loadDataPage: false,

            // active account 
            accountNotActive: true,
            accounActivetNotActive: true,
            usuarioDataExpiredDate: '',
        }
    },
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BCardText,
        BTabs,
        BTab,
        BCard,
        BCol,
        BRow,
        BFormTextarea,
        BFormCheckbox,
        vSelect,

        // date
        flatPickr,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    watch: {
        activeDate(val) {
            // console.log(val, this.activeDateDB)
            if(!this.expirationDate) {
                var formatDateOrigin = moment(val,'MM/DD/YYYY').format('MM/DD/YYYY')
                var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
                var expiredDate = moment(formatDate).add(12, 'months')
                var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('MM-DD-YYYY')
                this.expirationDate = dateUpdateFormat
                // console.log(this.expirationDate)
                this.usuarioDataExpiredDate = moment(expiredDate).format('x')


                var dateExpired = parseInt(this.usuarioDataExpiredDate, 10);
                var day1 = new Date(); 
                var day2 = new Date(dateExpired);
                var difference= day2-day1;
                var days = difference/(1000 * 3600 * 24);
                if(days > 0) {
                    this.accounActivetNotActive = true
                } else {
                    this.accounActivetNotActive = false
                }

            } else {
                var formatDateOrigin = moment(val,'MM/DD/YYYY').format('MM/DD/YYYY')
                var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
                var expiredDate = moment(formatDate).add(0, 's')

                if(this.activeDateDB !== val) {
                    var expiredDateReal = moment(formatDate).add(12, 'months')
                    var dateUpdateFormat = moment(expiredDateReal,'MM/DD/YYYY').format('MM-DD-YYYY')
                    this.expirationDate = dateUpdateFormat
                }
                // console.log(this.expirationDate)
                // var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('MM-DD-YYYY')
                
                // var dateUpdateFormat = moment(expiredDate).format('LLLL')
                this.usuarioDataExpiredDate = moment(expiredDate).format('x')
                // console.log(this.usuarioDataExpiredDate)
                var dateExpired = parseInt(this.usuarioDataExpiredDate, 10);
                var day1 = new Date(); 
                var day2 = new Date(dateExpired);
                var difference= day2-day1;
                var days = difference/(1000 * 3600 * 24);
                if(days > 0) {
                    this.accounActivetNotActive = true
                } else {
                    this.accounActivetNotActive = false
                }
            }

            // mark as not active
            if(val) {
                // console.log(val)
                var formatDateOrigin = moment(val,'MM/DD/YYYY').format('MM/DD/YYYY')
                var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
                var activeDate = moment(formatDate).add(0, 's')
                this.usuarioDataExpiredDate = moment(activeDate).format('x')

                var dateExpired = parseInt(this.usuarioDataExpiredDate, 10)
                var day1 = new Date(); 
                var day2 = new Date(dateExpired);
                var difference= day2-day1;
                var days = difference/(1000 * 3600 * 24);
                // console.log(days)
                if(days > 0) {
                    this.accounActivetNotActive = true
                } else {
                    this.accounActivetNotActive = false
                }
            }
        },
        expirationDate(val) {
            if(!val) {
                var formatDateOrigin = moment(val,'MM/DD/YYYY').format('MM/DD/YYYY')
                var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
                var expiredDate = moment(formatDate).add(0, 's')
                this.usuarioDataExpiredDate = moment(expiredDate).format('x')

                var dateExpired = parseInt(this.usuarioDataExpiredDate, 10);
                var day1 = new Date(); 
                var day2 = new Date(dateExpired);
                var difference= day2-day1;
                var days = difference/(1000 * 3600 * 24);
                if(Math.trunc(days) < 0) {
                    this.accountNotActive = true
                } else {
                    this.accountNotActive = false
                }
            } else {
                var formatDateOrigin = moment(val,'MM/DD/YYYY').format('MM/DD/YYYY')
                var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
                var expiredDate = moment(formatDate).add(0, 's')
                this.usuarioDataExpiredDate = moment(expiredDate).format('x')

                var dateExpired = parseInt(this.usuarioDataExpiredDate, 10)
                var day1 = new Date(); 
                var day2 = new Date(dateExpired);
                var difference= day2-day1;
                var days = difference/(1000 * 3600 * 24);
                if(Math.trunc(days) < 0) {
                    this.accountNotActive = true
                } else {
                    this.accountNotActive = false
                }
            }
        }
    },
    methods: {
        ...mapActions(['updateClinicianDetails']),
        validationForm() {
            this.$refs.addClinicianRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    this.submitUsuario()
                }
            })
        },
        submitUsuario() {
            if(this.email !== this.emailOld) {
                this.$http.get(`https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/auth/update/usuarios/email/data/${this.idClinicianDependency}/${this.email}`)
                .then(res => { 
                    // console.log(res.data, res.status)
                    if(res.status === 200) {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Email changed.`,
                                icon: 'CoffeeIcon',
                                variant: 'success',
                            },
                        })
                    }
                })
            }

            if(this.passValue && this.passConfirm) {
                let dataBody = JSON.stringify({
                    "id": this.idClinicianDependency,
                    "pass": this.passValue
                });
                var config = {
                    'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/auth/update/usuarios/data',
                    'method': 'POST',
                    'timeout': 0,
                    'headers': {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    'data': dataBody
                }

                this.axios(config)
                .then(res => {
                    if(res.status === 200) {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Password changed.`,
                                icon: 'CoffeeIcon',
                                variant: 'success',
                            },
                        })
                    }
                })
                .catch(e => console.log(e.message))

                // this.$http.get(`https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/auth/update/usuarios/data/${this.$route.params.id}/${this.passValue}`)
                // .then(res => { 
                //     // console.log(res.data, res.status)
                //     if(res.status === 200) {
                //         this.$toast({
                //             component: ToastificationContent,
                //             position: 'top-right',
                //             props: {
                //                 title: `Password changed.`,
                //                 icon: 'CoffeeIcon',
                //                 variant: 'success',
                //             },
                //         })
                //     }
                // })
            }

            let objectUsuario = {
                id: this.idClinicianDependency,
                firstName: this.firstName,
                lastName: this.lastName,
                agency: this.agency,
                email: this.email,
                countryOfResidence: this.countryOfResidence,
                region: this.region,
                borough: this.borough,
                program: this.program,
                programType: this.programType,

                // details
                licenseType: this.licenseType,
                licenseNumber: this.licenseNumber,
                insuranceTypesAccepted: this.insuranceTypesAccepted,
                specialities: this.specialities,
                languages: this.languages,
                mailingAddress: this.mailingAddress,
                city: this.city,
                state: this.state,
                zip: this.zip,
                contact: this.contact,

                // Attributes
                attributes: this.attributes,
                providesRemoteAndOrOffSiteSessionsIfRequired: this.providesRemoteAndOrOffSiteSessionsIfRequired,
                
                // notes
                notes: this.notes,
                role: 'clinician-staff'
            }
            // this.updateClinicianDetails(objectUsuario)

            // let objectUsuario = {
            //     id: this.idClinicianDependency,
            //     firstName: this.firstName,
            //     lastName: this.lastName,
            //     email: this.email,
            //     role: 'clinician-staff'
            // }
            this.editClinicianTracking()
            this.updateClinicianDetails(objectUsuario)
        },
        editClinicianTracking() {
            if(this.accountNotActive || this.accounActivetNotActive) {
                // console.log(this.usuarioDataExpiredDate)
                db.collection('usuarios').doc(this.idClinicianDependency).update({
                    expiredDate: this.usuarioDataExpiredDate
                })
            }

            if(!this.accounActivetNotActive && !this.accountNotActive) {
                db.collection('usuarios').doc(this.idClinicianDependency).update({
                    statusReferral: 'active'
                })
            } else {
                db.collection('usuarios').doc(this.idClinicianDependency).update({
                    statusReferral: 'inactive'
                })
            }

            db.collection('usuarios').doc(this.idClinicianDependency).update({
                activeDateTrack: this.activeDate,
                expirationDateTrack: this.expirationDate
            })

            var objTrack = [
                {text: '2 Day Training Completed Date', name: 'twoDaysTrainingCompletedDate', value: null},
                {text: 'Webinar CBT Completed Date', name: 'webinarCBTCompletedDate', value: null},
                {text: 'Webinar MI Completed Date', name: 'webinarMICompletedDate', value: null},
                {text: 'Collaborative Interview Completed Date', name: 'collaborativeInterviewCompletedDate', value: null},
                {text: 'MOU Signed Date', name: 'MOUSignedDate', value: null},
                {text: 'Malpractice Insurance Expiration Date', name: 'malpracticeInsuranceExpirationDate', value: null},
                {text: '60 Hour Course', name: 'sixtyHourCourse', value: null},
                {text: 'License Expiration Date', name: 'licenseExpirationDate', value: null},
                {text: 'Active Date', name: 'activeDate', value: this.activeDate},
                {text: 'Expiration Date', name: 'expirationDate', value: this.expirationDate},
                {text: 'Advanced Training Completed Date', name: 'advancedTrainingCompletedDate', value: null}
            ]

            db.collection('usuarios').doc(this.idClinicianDependency).collection('details').doc('trackingDetail').update({datePickers: objTrack})
        }
    },
    created() {
        const user = auth.currentUser
        if(user) {
            db.collection('usuarios').doc(user.uid).get()
            .then(userDoc => {
                if(userDoc.data().role === 'admin') {
                    this.adminOrManageUsers = true
                } else if(userDoc.data().role === 'staff') {
                    if(userDoc.data().permissions.includes('manage users')) {
                        this.adminOrManageUsers = true
                    } else if(userDoc.data().permissions.includes('data specialist')) {
                        this.dataSpecialist = true
                    } else if(userDoc.data().permissions.includes('clinician viewer')) {
                        this.clinicianOrClinicianViewer = true
                    } else {
                        this.clinicianOrClinicianViewer = true
                    }
                } else if(userDoc.data().role === 'clinician') {
                    this.clinicianUsers = true
                }

                if(userDoc.data().role === 'clinician-staff') {
                    this.idClinicianDependency = userDoc.id
                    this.emailOld = userDoc.data().email ? userDoc.data().email : ''

                    this.firstName = userDoc.data().firstName ? userDoc.data().firstName : ''
                    this.lastName = userDoc.data().lastName ? userDoc.data().lastName : ''
                    this.agency = userDoc.data().agency ? userDoc.data().agency : ''
                    this.email = userDoc.data().email ? userDoc.data().email : ''
                    this.countryOfResidence = userDoc.data().countryOfResidence ? userDoc.data().countryOfResidence : ''
                    this.region = userDoc.data().region ? userDoc.data().region : ''
                    this.borough = userDoc.data().borough ? userDoc.data().borough : ''
                    this.program = userDoc.data().program ? userDoc.data().program : ''
                    this.programType = userDoc.data().programType ? userDoc.data().programType : ''
                    
                    // details
                    this.licenseType = userDoc.data().licenseType ? userDoc.data().licenseType : ''
                    this.licenseNumber = userDoc.data().licenseNumber ? userDoc.data().licenseNumber : ''
                    this.insuranceTypesAccepted = userDoc.data().insuranceTypesAccepted ? userDoc.data().insuranceTypesAccepted : ''
                    this.specialities = userDoc.data().specialities ? userDoc.data().specialities : ''
                    this.languages = userDoc.data().languages ? userDoc.data().languages : ''
                    this.mailingAddress = userDoc.data().mailingAddress ? userDoc.data().mailingAddress : ''
                    this.city = userDoc.data().city ? userDoc.data().city : ''
                    this.state = userDoc.data().state ? userDoc.data().state : ''
                    this.zip = userDoc.data().zip ? userDoc.data().zip : ''
                    this.contact = userDoc.data().contact ? userDoc.data().contact : ''

                    // Attributes
                    this.attributes = userDoc.data().attributes ? userDoc.data().attributes : ''
                    this.providesRemoteAndOrOffSiteSessionsIfRequired = userDoc.data().providesRemoteAndOrOffSiteSessionsIfRequired ? userDoc.data().providesRemoteAndOrOffSiteSessionsIfRequired : false

                    // notes
                    this.notes = userDoc.data().notes ? userDoc.data().notes : ''

                    // status
                    this.statusReferral = userDoc.data().statusReferral ? userDoc.data().statusReferral : ''

                    // active account
                    this.activeDate = userDoc.data().activeDateTrack ? userDoc.data().activeDateTrack : null
                    this.expirationDate = userDoc.data().expirationDateTrack ? userDoc.data().expirationDateTrack : null
                }
                
                this.userData = userDoc.data()
                this.loadDataPage = true
            })
        }

        db.collection('taxonomies').doc('clinician').get()
        .then(docTaxonomia => {
            // profile fields
            this.optionsRegion = docTaxonomia.data().region
            this.optionsProgram = docTaxonomia.data().program
            this.optionsAttributes = docTaxonomia.data().clinicianAttributes

            // clinician details fields
            this.optionsLicenseType = docTaxonomia.data().licenseType
            this.optionsInsuranceTypesAccepted = docTaxonomia.data().insuranceTypesAcepted
            this.optionsSpecialities = docTaxonomia.data().specialities
            this.optionsLanguages = docTaxonomia.data().languages

            // tracking details fields
            // this.regionalCFEAssigned = docTaxonomia.data().regionalCFEAssigned
        })

        db.collection('taxonomies').doc('client').get()
        .then(docTaxonomia => {
            this.optionsCountryOfResidence = docTaxonomia.data().countryOfResidence
        })
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
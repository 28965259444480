<template>
    <div>
        <h2 class="mb-1">Clinician</h2>
        <b-breadcrumb
            class="breadcrumb-slash mb-0 ml-0 pl-50 mb-50"
            :items="itemsBreadcrumb"
            v-if="!clinicianUsers"
        />
        <p v-if="clinicianUsers" class="font-weight-bold h5 ml-0 mb-50">
            Clinicians /
            {{ userData.agency ? userData.agency : '' }} · 
            {{ userData.firstName ? userData.firstName : '' + ' ' + userData.lastName ? userData.lastName : '' }} 
            {{ userData.lastName ? userData.lastName : '' }}
        </p>
        <!-- {{statusEdit}} -->
        <b-card>
            <b-tabs v-if="isClinicianStaff">
                <p class="font-weight-bold h5 ml-1 mb-50">Your Account</p>
                <b-tabs
                    vertical
                    nav-wrapper-class="nav-vertical"
                    v-if="loadDataPage"
                >
                    <b-tab
                        :active="routeForReferral ? false : true"
                        title="Clinician Details"
                        @click="changeStatusEdit"
                    >
                        <edit-clinician-staff-details />
                    </b-tab>
                    <b-tab
                        title="Clinician Account"
                        disabled
                        class="font-weight-bold h5 ml-1 mb-50"
                    >
                    </b-tab>
                    <!-- <b-tab
                        :active="routeForReferral ? false : true"
                        title="Clinician Details"
                        @click="changeStatusEdit"
                    >
                        <edit-clinician-details />
                    </b-tab> -->
                    <b-tab
                        :active="routeForReferral ? true : false"
                        title="Referral Details"
                        @click="changeStatusEdit"
                    >
                        <edit-clinician-referral-details />
                    </b-tab>
                    <!-- <b-tab
                        title="Tracking Details"
                        @click="changeStatusEdit"
                    >
                        <edit-clinician-tracking-details />
                    </b-tab> -->
                    <b-tab
                        title="Your Client"
                        disabled
                        class="font-weight-bold h5 ml-1 mb-50"
                        v-if="!clinicianViewer"
                    >
                    </b-tab>
                    <b-tab
                        title="Monthly Claim Forms"
                        @click="changeStatusEdit"
                        v-if="!clinicianViewer"
                    >
                        <edit-clinician-claims @statusEditClinician="statusEdit = $event" :changeStatusEditClinician="statusEdit" />
                    </b-tab>
                    <b-tab
                        title="Client Activity Forms - VIEW ONLY"
                        @click="changeStatusEdit"
                        v-if="!clinicianViewer"
                    >
                        <edit-clinician-client-activity @statusEditClinician="statusEdit = $event" :changeStatusEditClinician="statusEdit" />
                    </b-tab>
                </b-tabs>
            </b-tabs>
            <b-tabs v-else>
                <p v-if="clinicianUsers || isStaff" class="font-weight-bold h5 ml-1 mb-50">Your Account</p>
                <b-tabs
                    vertical
                    nav-wrapper-class="nav-vertical"
                    v-if="loadDataPage"
                >
                    <b-tab
                        :active="routeForReferral ? false : true"
                        title="Clinician Details"
                        @click="changeStatusEdit"
                    >
                        <edit-clinician-details />
                    </b-tab>
                    <b-tab
                        :active="routeForReferral ? true : false"
                        title="Referral Details"
                        @click="changeStatusEdit"
                    >
                        <edit-clinician-referral-details />
                    </b-tab>
                    <b-tab
                        title="Tracking Details"
                        v-if="(isStaff || isAdmin) && !clinicianViewer"
                        @click="changeStatusEdit"
                    >
                        <edit-clinician-tracking-details />
                    </b-tab>
                    <b-tab
                        title="Claims"
                        v-if="!clinicianViewer && !clinicianUsers"
                        @click="changeStatusEdit"
                    >
                        <edit-clinician-claims @statusEditClinician="statusEdit = $event" :changeStatusEditClinician="statusEdit" />
                    </b-tab>
                    <b-tab
                        title="Client Activity"
                        v-if="!clinicianViewer && !clinicianUsers"
                        @click="changeStatusEdit"
                    >
                        <edit-clinician-client-activity @statusEditClinician="statusEdit = $event" :changeStatusEditClinician="statusEdit" />
                    </b-tab>
                    <b-tab
                        title="Your Client"
                        disabled
                        class="font-weight-bold h5 ml-1 mb-50"
                        v-if="!clinicianViewer && ((clinicianUsers && statusReferral === 'active' && (programClinician === 'PGRC - Group Practice' || programClinician === 'PGRC - OASAS Program' || programClinician === 'PGRC - Private Practitioner')) || isStaff)"
                    >
                    </b-tab>
                    <b-tab
                        title="Monthly Claim Forms"
                        @click="changeStatusEdit"
                        v-if="!clinicianViewer && ((clinicianUsers && statusReferral === 'active' && (programClinician === 'PGRC - Group Practice' || programClinician === 'PGRC - OASAS Program' || programClinician === 'PGRC - Private Practitioner')) || isStaff)"
                    >
                        <edit-clinician-claims @statusEditClinician="statusEdit = $event" :changeStatusEditClinician="statusEdit" />
                    </b-tab>
                    <b-tab
                        title="Client Activity Forms - VIEW ONLY"
                        @click="changeStatusEdit"
                        v-if="!clinicianViewer && ((clinicianUsers && statusReferral === 'active' && (programClinician === 'PGRC - Group Practice' || programClinician === 'PGRC - OASAS Program' || programClinician === 'PGRC - Private Practitioner')) || isStaff)"
                    >
                        <edit-clinician-client-activity @statusEditClinician="statusEdit = $event" :changeStatusEditClinician="statusEdit" />
                    </b-tab>
                    <b-tab
                        title="Clinician Staff"
                        v-if="adminOrManageUsers || programClinician === 'PGRC - Group Practice' || programClinician === 'PGRC - OASAS Program'"
                        @click="changeStatusEdit"
                    >
                        <edit-clinician-clinician-staff @statusEditClinician="statusEdit = $event" :changeStatusEditClinician="statusEdit" />
                    </b-tab>
                </b-tabs>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BTabs, BTab, BCardText, BCard, BCol, BRow, BFormTextarea,
  BBreadcrumb,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import EditClinicianStaffDetails from './EditClinicianStaffDetails.vue'
import EditClinicianDetails from './EditClinicianDetails.vue'
import EditClinicianReferralDetails from './EditClinicianReferralDetails.vue'
import EditClinicianTrackingDetails from './EditClinicianTrackingDetails.vue'
import EditClinicianClaims from './EditClinicianClaims.vue'
import EditClinicianClientActivity from './EditClinicianClientActivity.vue'
import EditClinicianClinicianStaff from './EditClinicianClinicianStaff.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { mapActions } from 'vuex'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            countries,
            itemsBreadcrumb: [],
            statusEdit: false,
            routeForReferral: false,
            
            rules: {
                required: v => !!v || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                email: v => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(v) || 'Email not valid.'
                },
            },

            // session role page config
            userData: '',
            statusReferral: '',
            clinicianViewer: false,
            clinicianUsers: false,
            dataSpecialist: false,
            adminOrManageUsers: false,
            loadDataPage: false,
            isStaff: false,
            isAdmin: false,
            isClinicianStaff: false,
            programClinician: '',
        }
    },
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BCardText,
        BTabs,
        BTab,
        BCard,
        BCol,
        BRow,
        BFormTextarea,
        BBreadcrumb,
        vSelect,

        // Form Validation
        ValidationProvider,
        ValidationObserver,

        // tabs edit
        EditClinicianStaffDetails,
        EditClinicianDetails,
        EditClinicianReferralDetails,
        EditClinicianTrackingDetails,
        EditClinicianClaims,
        EditClinicianClientActivity,
        EditClinicianClinicianStaff,
    },
    directives: {
        Ripple,
    },
    methods: {
        changeStatusEdit() {
            this.statusEdit = false
        }
    },
    created() {
        if(this.$route.params.destination) this.$route.params.destination === 'referral' ? this.routeForReferral = true : this.routeForReferral = false

        const user = auth.currentUser
        if(user) {
            // db.collection('clientsActivity').where('clinician', 'array-contains', 'xdaGx9BCYwWAKZTuUXKd1TUSzp62').get()
            // .then(docsAct => {
            //     console.log(docsAct.size)
            //     docsAct.forEach(ele => {
            //         if(ele.data().clinician.length === 1) {
            //             db.collection('clientsActivity').doc(ele.id).delete().then(() => console.log('Delete client activity'))
            //         } else {
            //             console.log(ele.id, ele.data().clinician.length)
            //         }
            //     })
            // })

            db.collection('usuarios').doc(user.uid).get()
            .then(userDoc => {
                if(userDoc.data().role === 'admin') {
                    this.isAdmin = true
                    this.adminOrManageUsers = true
                } else if(userDoc.data().role === 'staff') {
                    this.isStaff = true
                    if(userDoc.data().permissions.includes('manage users')) {
                        this.adminOrManageUsers = true
                    } else if(userDoc.data().permissions.includes('data specialist')) {
                        this.dataSpecialist = true
                    } else if(userDoc.data().permissions.includes('clinician viewer')) {
                        this.clinicianViewer = true
                    } else {
                        this.clinicianViewer = true
                    }
                } else if(userDoc.data().role === 'clinician') {
                    this.clinicianUsers = true
                    this.programClinician = userDoc.data().program ? userDoc.data().program : ''
                }

                if(userDoc.data().role === 'clinician-staff') {
                    this.isClinicianStaff = true
                    this.clinicianUsers = true
                    this.programClinician = userDoc.data().program ? userDoc.data().program : ''

                    db.collection('usuarios').doc(userDoc.data().dependency).get()
                    .then(itemClinician => {
                        this.userData = itemClinician.data()
                        this.itemsBreadcrumb.push(
                            { text: 'Clinicians', to: { name: 'all-clinicians' } },
                            { text: itemClinician.data().agency + ' · ' + itemClinician.data().firstName + ' ' + itemClinician.data().lastName, active: true }
                        )
                    })
                } else {
                    db.collection('usuarios').doc(this.$route.params.id).get()
                    .then(itemClinician => {
                        this.itemsBreadcrumb.push(
                            { text: 'Clinicians', to: { name: 'all-clinicians' } },
                            { text: itemClinician.data().agency + ' · ' + itemClinician.data().firstName + ' ' + itemClinician.data().lastName, active: true }
                        )
                    })
                    this.userData = userDoc.data()
                }

                // status
                this.statusReferral = userDoc.data().statusReferral ? userDoc.data().statusReferral : ''
                this.loadDataPage = true
            })
        }
    }
}
</script>

<style>
.nav-link.disabled {
    text-align: left;
    color: #000 !important;
    justify-content: inherit;
    margin-top: 10px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
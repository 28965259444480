<template>
    <div>
        <!-- {{changeStatusEditClinician}} -->
        <div v-if="!statusEdit">
            <b-row>
                <b-col
                    cols="12"
                    md="12"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                    <b-form-input
                        v-model="clientActivityName"
                        class="d-inline-block mr-1"
                        placeholder="Client name"
                        v-on:keyup="searchClientActivityUserName(clientActivityName)"
                    />
                    <b-form-select
                        v-model="clientActivityStatus"
                        inline
                        :options="optionsActivityClientStatus"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        class="d-inline-block mr-50"
                    />
                </b-col>
            </b-row>
            <h2 class="mb-1">{{totalRows.toLocaleString('en-US')}} <span style="font-size: 14px; color: #000;"> results found</span></h2>
            <b-table
                :items="arrayFiltradoClientActivityUser"
                :fields="fieldsTable"
                responsive="sm"
                hover
                @row-clicked="onRowSelected"
            >
                <template #cell(clientID)="data">
                    #{{data.item.clientID}}
                </template>
            </b-table>
        </div>
        <div v-if="idClientActivity && statusEdit">
            <edit-client-activity :idClientActivity="idClientActivity" @statusEditClientActivity="statusEdit = $event" @pushEditButton="pushEditButton = $event" />
        </div>
    </div>
</template>

<script>
import { 
    BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTable, BFormInvalidFeedback, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import EditClientActivity from './components/EditClientActivity.vue'
import flatPickr from 'vue-flatpickr-component'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            totalRows: 0,
            statusEditClinician: false,
            idClinicianDependency: '',

            // settings table and edit view
            statusEdit: false,
            pushEditButton: false,
            itemsActivity: [],
            fieldsTable: [
                { key: 'clientID', sortable: false },
                { key: 'clientName', sortable: false },
                { key: 'statusLogicOnHold', label: 'status', sortable: false },
                { key: 'contactDate', sortable: false },
                { key: 'admissionDate', sortable: false },
                { key: 'dischargeDate', sortable: false },
                // { key: 'actions', sortable: false },
            ],

            // get data from db
            optionsClient: [],
            optionsClinician: [],

            // filter
            clientActivityName: null,
            clientActivityStatus: null,
            optionsActivityClientStatus: [
                {value: null, text: 'Any Client Status'},
                {value: 'discharged', text: 'Discharged'},
                {value: 'admitted', text: 'Admitted'},
                {value: 'Engaged', text: 'Engaged'},
                {value: 'Closed - Attended First Session', text: 'Closed - Attended First Session'},
                {value: 'Closed - Did not attend first session', text: 'Closed - Did not attend first session'},
                {value: 'Closed - Received information', text: 'Closed - Received information'},
                {value: 'Closed - Referred to OASAS program', text: 'Closed - Referred to OASAS Program'},
                {value: 'External Referral', text: 'External Referral'},
                {value: 'On Hold', text: 'On Hold'}
            ],

            // edit client activity
            statusEdit: false,
            idClientActivity: '',
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTable,
        BFormInvalidFeedback,
        BFormSelect,
        
        // select
        vSelect,

        // date
        flatPickr,

        // Form Validation
        ValidationProvider,
        ValidationObserver,

        // components
        EditClientActivity,
    },
    directives: {
        Ripple,
    },
    props: ['changeStatusEditClinician'],
    computed: {
        ...mapGetters(['arrayFiltradoClientActivity','arrayFiltradoClientActivityUser']),
    },
    watch: {
        clientActivityStatus(val) {
            // console.log(val)
            this.searchClientActivityUserStatus(val)
        },
        arrayFiltradoClientActivityUser(val) {
            this.totalRows = val.length
        },
        statusEdit(val) {
            if(val) {
                this.statusEditClinician = true
                this.$emit('statusEditClinician',true)
            } else {
                this.statusEditClinician = false
                this.$emit('statusEditClinician',false)
            }
        },
        changeStatusEditClinician(val) {
            if(val) {
                this.statusEditClinician = true
                this.statusEdit = true
                this.$emit('statusEditClinician',true)
            } else {
                this.statusEditClinician = false
                this.statusEdit = false
                this.$emit('statusEditClinician',false)
            }
        }
    },
    methods: {
        ...mapActions(['setSnapshotClientActivityUser','searchClientActivityUserName','searchClientActivityUserStatus','searchClientActivityName','searchClientActivityStatus','searchClientActivityClinicianName','searchClientActivityClinicianRegion','searchClientActivityClinicianStatus','searchClientActivityClientID','searchClientActivityStartDate','searchClientActivityEndDate','setSnapshotClientActivity']),
        onRowSelected(items) {
            this.idClientActivity = items.id
            this.statusEdit = true
            // console.log(this.idClientActivity)
        },
    },
    created() {
        this.searchClientActivityUserName('')
        this.searchClientActivityUserStatus('')
        this.searchClientActivityName('')
        this.searchClientActivityStatus('')

        this.searchClientActivityClinicianName('')
        this.searchClientActivityClinicianRegion('')
        this.searchClientActivityClinicianStatus('')
        this.searchClientActivityClientID('')
        this.searchClientActivityStartDate('')
        this.searchClientActivityEndDate('')

        const user = auth.currentUser
        db.collection('usuarios').doc(user.uid).get()
        .then(userDoc => {
            if(userDoc.data().role === 'clinician-staff') {
                // this.idClinicianDependency = userDoc.data().dependency
                this.idClinicianDependency = user.uid
                // db.collection('clientsActivity').where('clinician','array-contains',userDoc.data().dependency)
                db.collection('clientsActivity').where('clinician','array-contains',user.uid)
                .onSnapshot(docsClients => {
                    this.itemsActivity = []
                    docsClients.forEach(dataClientActivity => {
                        db.collection('clients').doc(dataClientActivity.data().client).get()
                        .then(itemClient => {
                            var filterArray = this.itemsActivity.filter(item => item.id === dataClientActivity.id)
                            if(!filterArray.length) {
                                var statusLogical = ''
                                var statusLogicOnHold = ''
                                // if(dataClientActivity.data().activityStatus !== 'On Hold') {
                                if(dataClientActivity.data().activityStatus) {
                                    if(dataClientActivity.data().dateOfAdmission && dataClientActivity.data().removeRecordDischargeData) {
                                        statusLogical = 'Admitted'
                                        statusLogicOnHold = dataClientActivity.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
                                    } else if(dataClientActivity.data().dateOfAdmission && (!dataClientActivity.data().dateOfDischarge || dataClientActivity.data().dateOfDischarge === 'N/A')) {
                                        statusLogical = 'Admitted'
                                        statusLogicOnHold = dataClientActivity.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
                                    } else if(dataClientActivity.data().dateOfDischarge && dataClientActivity.data().dateOfDischarge !== 'N/A') {
                                        statusLogical = 'Discharged'
                                        statusLogicOnHold = dataClientActivity.data().activityStatus === 'On Hold' ? 'Discharged (On Hold)' : 'Discharged'
                                    } else {
                                        statusLogical = dataClientActivity.data().activityStatus
                                        statusLogicOnHold = dataClientActivity.data().activityStatus
                                    }
                                } else {
                                    statusLogical = dataClientActivity.data().activityStatus
                                    statusLogicOnHold = dataClientActivity.data().activityStatus
                                }

                                var obj = {
                                    id: dataClientActivity.id,
                                    clientID: itemClient.data().id,
                                    clientName: itemClient.data().firstName + ' ' + itemClient.data().lastName,
                                    status: statusLogical,
                                    statusLogical: dataClientActivity.data().activityStatusLogical ? dataClientActivity.data().activityStatusLogical : '',
                                    statusLogicOnHold: statusLogicOnHold,
                                    contactDate: dataClientActivity.data().dateOfContact,
                                    admissionDate: dataClientActivity.data().dateOfAdmission ? dataClientActivity.data().dateOfAdmission : '',
                                    dischargeDate: dataClientActivity.data().dateOfDischarge ? dataClientActivity.data().dateOfDischarge : '',
                                }
                                this.itemsActivity.push(obj)
                                this.itemsActivity.sort(function(a,b) {
                                    return new Date(b.contactDate.split('-')) - new Date(a.contactDate.split('-'));
                                })
                            }
                        })
                    })
                    this.setSnapshotClientActivityUser(this.itemsActivity)
                })
            } else {
                this.idClinicianDependency = this.$route.params.id
                db.collection('clientsActivity').where('clinician','array-contains',this.$route.params.id)
                .onSnapshot(docsClients => {
                    this.itemsActivity = []
                    docsClients.forEach(dataClientActivity => {
                        db.collection('clients').doc(dataClientActivity.data().client).get()
                        .then(itemClient => {
                            var filterArray = this.itemsActivity.filter(item => item.id === dataClientActivity.id)
                            if(!filterArray.length) {
                                var statusLogical = ''
                                var statusLogicOnHold = ''
                                // if(dataClientActivity.data().activityStatus !== 'On Hold') {
                                if(dataClientActivity.data().activityStatus) {
                                    if(dataClientActivity.data().dateOfAdmission && dataClientActivity.data().removeRecordDischargeData) {
                                        statusLogical = 'Admitted'
                                        statusLogicOnHold = dataClientActivity.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
                                    } else if(dataClientActivity.data().dateOfAdmission && (!dataClientActivity.data().dateOfDischarge || dataClientActivity.data().dateOfDischarge === 'N/A')) {
                                        statusLogical = 'Admitted'
                                        statusLogicOnHold = dataClientActivity.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
                                    } else if(dataClientActivity.data().dateOfDischarge && dataClientActivity.data().dateOfDischarge !== 'N/A') {
                                        statusLogical = 'Discharged'
                                        statusLogicOnHold = dataClientActivity.data().activityStatus === 'On Hold' ? 'Discharged (On Hold)' : 'Discharged'
                                    } else {
                                        statusLogical = dataClientActivity.data().activityStatus
                                        statusLogicOnHold = dataClientActivity.data().activityStatus
                                    }
                                } else {
                                    statusLogical = dataClientActivity.data().activityStatus
                                    statusLogicOnHold = dataClientActivity.data().activityStatus
                                }

                                var obj = {
                                    id: dataClientActivity.id,
                                    clientID: itemClient.data().id,
                                    clientName: itemClient.data().firstName + ' ' + itemClient.data().lastName,
                                    status: statusLogical,
                                    statusLogical: dataClientActivity.data().activityStatusLogical ? dataClientActivity.data().activityStatusLogical : '',
                                    statusLogicOnHold: statusLogicOnHold,
                                    contactDate: dataClientActivity.data().dateOfContact,
                                    admissionDate: dataClientActivity.data().dateOfAdmission ? dataClientActivity.data().dateOfAdmission : '',
                                    dischargeDate: dataClientActivity.data().dateOfDischarge ? dataClientActivity.data().dateOfDischarge : '',
                                }
                                this.itemsActivity.push(obj)
                                this.itemsActivity.sort(function(a,b) {
                                    return new Date(b.contactDate.split('-')) - new Date(a.contactDate.split('-'));
                                })
                            }
                        })
                    })
                    this.setSnapshotClientActivityUser(this.itemsActivity)
                })
            }
        })
    },
    mounted() {
        this.$emit('statusEditClinician',false)
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
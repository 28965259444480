<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 text-capitalize">
        <h5 class="mb-0">
          Clinician Staff
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
        <!-- Form -->
      <validation-observer ref="simpleRules">
        <b-form
          class="p-2"
        >
          <h5>Details</h5>
          <hr class="mt-0">
          <!-- First Name -->
          <b-form-group
            label-for="first-name"
          >
          <template v-slot:label>
              First Name <span class="text-danger">*</span>
          </template>
            <validation-provider
              #default="{ errors }"
              name="First Name"
              rules="required"
            >
              <b-form-input
                id="first-name"
                v-model="firstName"
                trim
                :state="errors.length > 0 ? false:null"
                placeholder="First Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          
          <!-- Last Name -->
          <b-form-group
            label-for="last-name"
          >
          <template v-slot:label>
              Last Name <span class="text-danger">*</span>
          </template>
            <validation-provider
              #default="{ errors }"
              name="Last Name"
              rules="required"
            >
              <b-form-input
                id="last-name"
                v-model="lastName"
                trim
                :state="errors.length > 0 ? false:null"
                placeholder="Last Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Email -->
          <b-form-group
            label-for="email"
          >
          <template v-slot:label>
              Email Address <span class="text-danger">*</span>
          </template>
            <validation-provider
              #default="{ errors }"
              name="Email Address"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="email"
                :state="errors.length > 0 ? false:null"
                placeholder="Email Address"
                trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click.prevent="validationForm"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox, BFormCheckboxGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, email, password, confirmed,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import moment from 'moment'
import { mapActions } from "vuex"

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    // roleOptions: {
    //   type: String,
    //   required: true,
    // },
  },
  data() {
    return {
        // validation
        required,
        password,
        confirmed,
        email,

        resetForm: true,
        countries,
        fullName: '',
        firstName: '',
        lastName: '',
        email: '',
        contact: '',
        password: '',
        passwordConfirm: '',
        selectedPermissions: [],
        permissionsOptions: ['clinician viewer','manage users','data specialist'],
        activateDate: null,
        expirationDate: null,
    }
  },
  watch: {
    isAddNewUserSidebarActive(val) {
        // console.log(val)
        this.firstName = ''
        this.lastName = ''
        this.email = ''
        this.$refs.simpleRules.reset()

        if(val) {
            var val = new Date()
            var formatDateOrigin = moment(val,'MM/DD/YYYY').format('MM/DD/YYYY')
            var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
            var expiredDate = moment(formatDate).add(12, 'months')
            this.activateDate = moment(val,'MM-DD-YYYY').format('MM-DD-YYYY')
            this.expirationDate = moment(expiredDate,'MM/DD/YYYY').format('MM-DD-YYYY')
            // console.log(this.activateDate, this.expirationDate)
        }
    }
  },
  methods: {
    ...mapActions(['agregarUsuarios','createClinician']),
    // submitUsuario(payload) {
    //   this.agregarUsuarios(payload)
    //   this.isAddNewUserSidebarActive = false
    //   this.fullName = ''
    //   this.firstName = ''
    //   this.lastName = ''
    //   this.email = ''
    //   this.contact = ''
    //   this.password = ''
    //   this.passwordConfirm = ''
    //   this.selectedPermissions = []
    // },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if(success) {
          // console.log({nombre: this.firstName + ' ' + this.lastName, firstName: this.firstName, lastName: this.lastName, email: this.email, contact: this.contact, password: this.password, role: this.roleOptions, permissions: this.selectedPermissions})
            let objectUsuario = {
                name: this.firstName + ' ' + this.lastName,
                nombre: this.firstName + ' ' + this.lastName,
                firstName: this.firstName,
                lastName: this.lastName,
                agency: '',
                email: this.email,
                countryOfResidence: '',
                region: '',
                borough: '',
                program: '',
                programType: '',

                // details
                licenseType: '',
                licenseNumber: '',
                insuranceTypesAccepted: '',
                specialities: '',
                languages: '',
                mailingAddress: '',
                city: '',
                state: '',
                zip: '',
                contact: '',

                // Attributes
                attributes: '',
                providesRemoteAndOrOffSiteSessionsIfRequired: false,

                // notes
                notes: '',
                role: 'clinician-staff',
                statusReferral: 'active',

                objectTracking: {
                  regionalCFE: '',
                  datePickers: [
                    {text: '2 Day Training Completed Date', name: 'twoDaysTrainingCompletedDate', value: null},
                    {text: 'Webinar CBT Completed Date', name: 'webinarCBTCompletedDate', value: null},
                    {text: 'Webinar MI Completed Date', name: 'webinarMICompletedDate', value: null},
                    {text: 'Collaborative Interview Completed Date', name: 'collaborativeInterviewCompletedDate', value: null},
                    {text: 'MOU Signed Date', name: 'MOUSignedDate', value: null},
                    {text: 'Malpractice Insurance Expiration Date', name: 'malpracticeInsuranceExpirationDate', value: null},
                    {text: '60 Hour Course', name: 'sixtyHourCourse', value: null},
                    {text: 'License Expiration Date', name: 'licenseExpirationDate', value: null},
                    {text: 'Active Date', name: 'activeDate', value: this.activateDate},
                    {text: 'Expiration Date', name: 'expirationDate', value: this.expirationDate},
                    {text: 'Advanced Training Completed Date', name: 'advancedTrainingCompletedDate', value: null}
                  ],
                  
                  // notes
                  notes: '',
                  referenceOneReceived: false,
                  referenceTwoReceived: false,
                  referenceThreeReceived: false,
                  wNine: false,
                  directDeposit: false
                },
                dependency: this.$route.params.id
            }
            this.createClinician(objectUsuario)
            this.isAddNewUserSidebarActive = false
            this.firstName = ''
            this.lastName = ''
            this.email = ''
            // this.submitUsuario({name: this.firstName + ' ' + this.lastName, firstName: this.firstName, lastName: this.lastName, email: this.email, role: 'clinician-staff'})
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<template>
    <div>
        <div class="mb-3">
            <validation-observer ref="addClinicianRules" v-if="program === 'PGRC - Private Practitioner' || program === 'PGRC - Group Practice'">
                <h4 v-if="program === 'PGRC - Private Practitioner' || program === 'PGRC - Group Practice'">Referral Details</h4>
                <hr v-if="program === 'PGRC - Private Practitioner' || program === 'PGRC - Group Practice'">
                <b-row cols="12" v-if="program === 'PGRC - Private Practitioner' || program === 'PGRC - Group Practice'">
                    <b-col md="6" v-if="program === 'PGRC - Private Practitioner' || program === 'PGRC - Group Practice'">
                        <!-- Accepting new clients -->
                        <validation-provider
                            #default="{ errors }"
                            name="Accepting new clients"
                            rules="required"
                        >
                            <b-form-group
                                label-for="Accepting new clients"
                                :state="errors.length > 0 ? false:null"
                            >
                            <template v-slot:label>
                                Accepting new clients <span class="text-danger">*</span>
                            </template>
                                <v-select
                                    v-model="acceptingNewClients"
                                    placeholder="Accepting new clients"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="optionsYesNo"
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="program === 'PGRC - Private Practitioner' || program === 'PGRC - Group Practice'">
                        <!-- Accepting referrals outside of catchment -->
                        <validation-provider
                            #default="{ errors }"
                            name="Accepting referrals outside of catchment"
                            rules="required"
                        >
                            <b-form-group
                                label-for="Accepting referrals outside of catchment"
                                :state="errors.length > 0 ? false:null"
                            >
                            <template v-slot:label>
                                Accepting referrals outside of catchment <span class="text-danger">*</span>
                            </template>
                                <v-select
                                    v-model="acceptingReferralsOutsideOfCatchment"
                                    placeholder="Accepting referrals outside of catchment"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="optionsYesNo"
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-col md="12" v-if="!clinicianOrClinicianViewer && !dataSpecialist" style="text-align: right;">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-0"
                        type="submit"
                        @click.prevent="validationForm"
                    >
                    Save
                    </b-button>
                </b-col>
            </validation-observer>
        </div>

        <!-- btn form repeater office -->
        <b-button
            variant="primary"
            class="float-right"
            style="padding: 8px;"
            @click="repeateAgain"
            :style="clinicianOrClinicianViewer || dataSpecialist ? 'display: none !important;' : ''"
        >
            <feather-icon
                icon="PlusIcon"
                size="16"
            />
        </b-button>
        <h4>Office locations</h4>
        <hr>
        <b-form
            ref="form"
            class="repeater-form"
            @submit.prevent="repeateAgain"
        >
            <!-- :style="{height: trHeight}" -->
            <!-- Row Loop -->
            <b-row
                v-for="(item, index) in items"
                :id="item.id"
                :key="item.id"
                ref="row"
            >
                <!-- <b-row cols="12"> -->
                    <b-col md="11">
                        <!-- Address -->
                        <b-col md="12" class="mx-0 px-0">
                            <b-form-group
                                label="Address"
                                label-for="Address"
                            >
                                <b-form-input
                                    type="text"
                                    placeholder="Address"
                                    v-model="item.address"
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                            </b-form-group>
                        </b-col>
                        
                        <b-row cols="12">
                            <!-- City -->
                            <b-col md="4">
                                <b-form-group
                                    label="City"
                                    label-for="city"
                                >
                                    <b-form-input
                                        type="text"
                                        placeholder="City"
                                        v-model="item.city"
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- State -->
                            <b-col md="4">
                                <b-form-group
                                    label="State"
                                    label-for="state"
                                >
                                    <b-form-input
                                        type="text"
                                        placeholder="State"
                                        v-model="item.state"
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- ZIP -->
                            <b-col md="4">
                                <b-form-group
                                    label="ZIP"
                                    label-for="zip"
                                >
                                    <b-form-input
                                        type="text"
                                        placeholder="ZIP"
                                        v-model="item.zip"
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <!-- Phone -->
                        <b-col md="12" class="mx-0 px-0">
                            <b-form-group
                                label="Phone number"
                                label-for="phone"
                            >
                                <b-form-input
                                    type="text"
                                    placeholder="Phone number"
                                    v-model="item.phone"
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                            </b-form-group>
                        </b-col>
                        
                        <b-row cols="12" v-for="(itemTime, indexTime) in item.arrayTime" :key="indexTime">
                            <!-- Office hours day -->
                            <b-col md="4">
                                <b-form-group
                                    label="Office hours day"
                                    label-for="office hours day"
                                >
                                    <v-select
                                        placeholder="Office hours day"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="optionsDay"
                                        v-model="itemTime.officeHoursDay"
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- Office open time -->
                            <b-col md="4">
                                <b-form-group
                                    label="Office open time"
                                    label-for="office open time"
                                >
                                    <v-select
                                        placeholder="Office open time"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="optionsTime"
                                        v-model="itemTime.officeOpenTime"
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- Office close time -->
                            <b-col md="4">
                                <b-form-group
                                    label="Office close time"
                                    label-for="office close time"
                                >
                                    <v-select
                                        placeholder="Office close time"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="optionsTime"
                                        v-model="itemTime.officeCloseTime"
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <!-- Handicap Accessible -->
                        <b-col md="12" class="mx-0 px-0">
                            <b-form-group
                                label="Handicap Accessible"
                                label-for="handicap accessible"
                            >
                                <v-select
                                    placeholder="Handicap Accessible"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="['Yes','No']"
                                    v-model="item.handicapAccessible"
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                            </b-form-group>
                        </b-col>
                        
                        <b-row>
                            <!-- Near public transportation -->
                            <b-col :md="item.nearPublicTransportation === 'Yes' ? '6' : '12'">
                                <b-form-group
                                    label="Near public transportation"
                                    label-for="near public transportation"
                                >
                                    <v-select
                                        placeholder="Near public transportation"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="['Yes','No']"
                                        v-model="item.nearPublicTransportation"
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- Transportation Notes -->
                            <b-col md="6" v-if="item.nearPublicTransportation === 'Yes'" class="mx-0 px-0">
                                <b-form-group
                                    label="Transportation Notes"
                                    label-for="Transportation Notes"
                                >
                                    <b-form-input
                                        type="text"
                                        v-model="item.transportationNotes"
                                        placeholder="Transportation Notes"
                                        trim
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <!-- Notes -->
                        <b-col md="12" class="mx-0 px-0">
                            <b-form-group
                                label="Notes"
                                label-for="Notes"
                            >
                                <b-form-textarea
                                    id="textarea-default"
                                    placeholder="Notes"
                                    rows="3"
                                    v-model="item.notes"
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                            </b-form-group>
                        </b-col>

                        <b-row class="mb-3">
                            <!-- Type of Service -->
                            <b-col :md="'12'">
                                <b-form-group
                                    label="Type of Service"
                                    label-for="type of service"
                                >
                                    <v-select
                                        placeholder="Type of Service"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="optionstypeService"
                                        v-model="item.typeService"
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- Accepting new clients -->
                            <b-col :md="'6'" v-if="program === 'PGRC - OASAS Program'">
                                <b-form-group
                                    label="Accepting new clients"
                                    label-for="accepting new clients"
                                >
                                    <v-select
                                        placeholder="Accepting new clients"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="['Yes','No']"
                                        v-model="item.acceptingNewClients"
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- Accept referrals outside of catchment -->
                            <b-col :md="'6'" v-if="program === 'PGRC - OASAS Program'">
                                <b-form-group
                                    label="Accept referrals outside of catchment"
                                    label-for="accept referrals outside of catchment"
                                >
                                    <v-select
                                        placeholder="Accept referrals outside of catchment"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="['Yes','No']"
                                        v-model="item.acceptReferralsOutsideOfCatchment"
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col md="1" :style="clinicianOrClinicianViewer || dataSpecialist ? 'display: none !important;' : ''">
                        <!-- Remove Button -->
                        <b-col
                            md="12"
                        >
                            <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="p-50 ml-1"
                                @click="removeItem(index)"
                            >
                            <feather-icon
                                icon="XIcon"
                            />
                            </b-button>
                        </b-col>
                    </b-col>
                <!-- </b-row> -->
                <b-col cols="12" class="mb-3">
                    <hr>
                </b-col>
            </b-row>
        </b-form>
        <!-- Form Actions -->
        <div class="d-flex mt-2 float-right" :style="clinicianOrClinicianViewer || dataSpecialist ? 'display: none !important;' : ''">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="items.length >= 1 ? false : true"
                @click.prevent="submitFormReferralDetail"
            >
            Save
            </b-button>
        </div>
    </div>
</template>

<script>
import { BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea,
    BFormInvalidFeedback, BCardText, BCard, BFormCheckbox,
 } from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email, min, confirmed } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            required,
            items: [],
            nextTodoId: 1,
            idClinicianDependency: '',
            acceptingNewClients: '',
            acceptingReferralsOutsideOfCatchment: '',
            optionsYesNo: [
                'Yes',
                'No'
            ],

            // form
            optionsDay: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday'
            ],
            optionsTime: [
                '12:00 AM', '12:15 AM', '12:30 AM', '12:45 AM',
                '01:00 AM', '01:15 AM', '01:30 AM', '01:45 AM',
                '02:00 AM', '02:15 AM', '02:30 AM', '02:45 AM',
                '03:00 AM', '03:15 AM', '03:30 AM', '03:45 AM',
                '04:00 AM', '04:15 AM', '04:30 AM', '04:45 AM',
                '05:00 AM', '05:15 AM', '05:30 AM', '05:45 AM',
                '06:00 AM', '06:15 AM', '06:30 AM', '06:45 AM',
                '07:00 AM', '07:15 AM', '07:30 AM', '07:45 AM',
                '08:00 AM', '08:15 AM', '08:30 AM', '08:45 AM',
                '09:00 AM', '09:15 AM', '09:30 AM', '09:45 AM',
                '10:00 AM', '10:15 AM', '10:30 AM', '10:45 AM',
                '11:00 AM', '11:15 AM', '11:30 AM', '11:45 AM',
                '12:00 PM', '12:15 PM', '12:30 PM', '12:45 PM',
                '01:00 PM', '01:15 PM', '01:30 PM', '01:45 PM',
                '02:00 PM', '02:15 PM', '02:30 PM', '02:45 PM',
                '03:00 PM', '03:15 PM', '03:30 PM', '03:45 PM',
                '04:00 PM', '04:15 PM', '04:30 PM', '04:45 PM',
                '05:00 PM', '05:15 PM', '05:30 PM', '05:45 PM',
                '06:00 PM', '06:15 PM', '06:30 PM', '06:45 PM',
                '07:00 PM', '07:15 PM', '07:30 PM', '07:45 PM',
                '08:00 PM', '08:15 PM', '08:30 PM', '08:45 PM',
                '09:00 PM', '09:15 PM', '09:30 PM', '09:45 PM',
                '10:00 PM', '10:15 PM', '10:30 PM', '10:45 PM',
                '11:00 PM', '11:15 PM', '11:30 PM', '11:45 PM',
            ],
            optionstypeService: [
                'In-person',
                'Telehealth',
                'Both'
            ],

            // data session
            clinicianOrClinicianViewer: false,
            clinicianUsers: false,
            dataSpecialist: false,
            adminOrManageUsers: false,
            loadDataPage: false,
            userData: '',
            program: ''
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BFormInvalidFeedback,
        BCardText,
        BCard,
        BFormCheckbox,
        
        vSelect,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    mixins: [heightTransition],
    mounted() {
        this.initTrHeight()
    },
    created() {
        window.addEventListener('resize', this.initTrHeight)
    },
    destroyed() {
        window.removeEventListener('resize', this.initTrHeight)
    },
    methods: {
        ...mapActions(['updateClinicianReferralDetails']),
        repeateAgain() {
            this.items.push({
                id: this.nextTodoId += this.items.length,
                address: '',
                city: '',
                state: '',
                zip: '',
                phone: '',
                arrayTime: [
                    {
                        officeHoursDay: '',
                        officeOpenTime: '',
                        officeCloseTime: '',
                    },
                    {
                        officeHoursDay: '',
                        officeOpenTime: '',
                        officeCloseTime: '',
                    },
                    {
                        officeHoursDay: '',
                        officeOpenTime: '',
                        officeCloseTime: '',
                    },
                    {
                        officeHoursDay: '',
                        officeOpenTime: '',
                        officeCloseTime: '',
                    },
                    {
                        officeHoursDay: '',
                        officeOpenTime: '',
                        officeCloseTime: '',
                    },
                    {
                        officeHoursDay: '',
                        officeOpenTime: '',
                        officeCloseTime: '',
                    },
                    {
                        officeHoursDay: '',
                        officeOpenTime: '',
                        officeCloseTime: '',
                    },
                ],
                handicapAccessible: '',
                nearPublicTransportation: '',
                transportationNotes: '',
                notes: '',
                typeService: '',
                acceptingNewClients: '',
                acceptReferralsOutsideOfCatchment: ''
            })

            this.$nextTick(() => {
                this.trAddHeight(this.$refs.row[0].offsetHeight)
            })
        },
        removeItem(index) {
            this.items.splice(index, 1)
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
        },
        initTrHeight() {
            this.trSetHeight(null)
            this.$nextTick(() => {
                this.trSetHeight(this.$refs.form.scrollHeight)
            })
        },
        submitFormReferralDetail() {
            // address, city, state, zip, phone, arrayTime, officeHoursDay, officeOpenTime, officeCloseTime, handicapAccessible, nearPublicTransportation, notes
            // console.log(this.items)
            if(this.items.length >= 1) {
                this.updateClinicianReferralDetails({id: this.idClinicianDependency, data: this.items})
            }
        },
        submitUsuario() {
            db.collection('usuarios').doc(this.$route.params.id).update({
                acceptingNewClients: this.acceptingNewClients ? this.acceptingNewClients : '',
                acceptingReferralsOutsideOfCatchment: this.acceptingReferralsOutsideOfCatchment ? this.acceptingReferralsOutsideOfCatchment : ''
            })
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Referral details updated`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                    },
                })
            })
        },
        validationForm() {
            this.$refs.addClinicianRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    this.submitUsuario()
                }
            })
        },
    },
    created() {
        const user = auth.currentUser
        if(user) {
            db.collection('usuarios').doc(user.uid).get()
            .then(userDoc => {
                if(userDoc.data().role === 'admin') {
                    this.adminOrManageUsers = true
                } else if(userDoc.data().role === 'staff') {
                    if(userDoc.data().permissions.includes('manage users')) {
                        this.adminOrManageUsers = true
                    } else if(userDoc.data().permissions.includes('data specialist')) {
                        this.dataSpecialist = true
                    } else if(userDoc.data().permissions.includes('clinician viewer')) {
                        this.clinicianOrClinicianViewer = true
                    } else {
                        this.clinicianOrClinicianViewer = true
                    }
                } else if(userDoc.data().role === 'clinician') {
                    this.clinicianUsers = true
                }

                if(userDoc.data().role === 'clinician-staff') {
                    this.idClinicianDependency = userDoc.data().dependency
                    // this.idClinicianDependency = user.uid
                    db.collection('usuarios').doc(userDoc.data().dependency)
                    // db.collection('usuarios').doc(user.uid)
                    .onSnapshot(userDoc => {
                        if(userDoc.exists) {
                            this.program = userDoc.data().program ? userDoc.data().program : ''
                            this.acceptingNewClients = userDoc.data().acceptingNewClients ? userDoc.data().acceptingNewClients : ''
                            this.acceptingReferralsOutsideOfCatchment = userDoc.data().acceptingReferralsOutsideOfCatchment ? userDoc.data().acceptingReferralsOutsideOfCatchment : ''
                        }
                    })

                    // db.collection('usuarios').doc(userDoc.data().dependency).collection('details').doc('referralDetail')
                    db.collection('usuarios').doc(user.uid).collection('details').doc('referralDetail')
                    .onSnapshot(doc => {
                        if(doc.data()) {
                            this.items = doc.data().referralDetail ? doc.data().referralDetail : []
                        } else {
                            this.items = []
                        }
                    })
                } else {
                    this.idClinicianDependency = this.$route.params.id
                    db.collection('usuarios').doc(this.$route.params.id)
                    .onSnapshot(userDoc => {
                        if(userDoc.exists) {
                            this.program = userDoc.data().program ? userDoc.data().program : ''
                            this.acceptingNewClients = userDoc.data().acceptingNewClients ? userDoc.data().acceptingNewClients : ''
                            this.acceptingReferralsOutsideOfCatchment = userDoc.data().acceptingReferralsOutsideOfCatchment ? userDoc.data().acceptingReferralsOutsideOfCatchment : ''
                        }
                    })

                    db.collection('usuarios').doc(this.$route.params.id).collection('details').doc('referralDetail')
                    .onSnapshot(doc => {
                        if(doc.data()) {
                            this.items = doc.data().referralDetail ? doc.data().referralDetail : []
                        } else {
                            this.items = []
                        }
                    })
                }
                
                this.userData = userDoc.data()
                this.loadDataPage = true
            })
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
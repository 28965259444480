<template>
    <div>
        <div class="text-center" v-if="!loadDataPage">
            <b-spinner variant="primary" label="Text Centered" />
            <h4 class="mt-1">LOADING...</h4>
        </div>

        <div v-else>
            <p class="font-weight-bold h5 ml-1 mb-50">Clinician Staff</p>
            <b-tabs
                vertical
                nav-wrapper-class="nav-vertical"
            >
                <b-tab
                    :active="routeForReferral ? false : true"
                    title="Staff Details"
                    @click="changeStatusEdit('det')"
                >
                    <!-- Edit clinician staff -->
                    <h3 class="mb-2 px-2">Clinician Staff <span style="color: #476282; font-size: 1.2rem;">· {{ firstName }} {{lastName}}</span></h3>
                    <validation-observer ref="addClinicianRules">
                        <b-form
                            class="px-2"
                        >
                            <b-row class="mb-3">
                                <b-col md="8">
                                    <!-- Profile -->
                                    <h4>Profile</h4>
                                    <hr>
                                    <b-row>
                                        <b-col md="6">
                                            <!-- First Name -->
                                            <b-form-group
                                                label-for="First name"
                                            >
                                            <template v-slot:label>
                                                First name <span class="text-danger">*</span>
                                            </template>
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="First name"
                                                    rules="required"
                                                >
                                                    <b-form-input
                                                        id="full-name"
                                                        v-model="firstName"
                                                        trim
                                                        :state="errors.length > 0 ? false:null"
                                                        placeholder="First name"
                                                        
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>

                                        <b-col md="6">
                                            <!-- Last Name -->
                                            <b-form-group
                                                label-for="Last name"
                                            >
                                            <template v-slot:label>
                                                Last name <span class="text-danger">*</span>
                                            </template>
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Last name"
                                                    rules="required"
                                                >
                                                    <b-form-input
                                                        id="full-name"
                                                        v-model="lastName"
                                                        trim
                                                        :state="errors.length > 0 ? false:null"
                                                        placeholder="Last name"
                                                        
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-col md="6" class="pl-0">
                                        <!-- Email -->
                                        <b-form-group
                                            label-for="email"
                                        >
                                        <template v-slot:label>
                                            Email <span class="text-danger">*</span>
                                        </template>
                                            <validation-provider
                                                #default="{ errors }"
                                                name="Email"
                                                rules="required|email"
                                            >
                                                <b-form-input
                                                    id="email"
                                                    v-model="emailClinicianStaff"
                                                    :state="errors.length > 0 ? false:null"
                                                    trim
                                                    placeholder="Email"
                                                    
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="6" class="pl-0">
                                        <b-form-group
                                            label-for="Active Date"
                                        >
                                        <template v-slot:label>
                                            Active Date <span class="text-danger">*</span>
                                        </template>
                                            <validation-provider
                                                #default="{ errors }"
                                                name="Active Date"
                                                rules="required"
                                            >
                                                <flat-pickr
                                                    v-model="activeDate"
                                                    class="form-control"
                                                    placeholder="Select a Date"
                                                    :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                                    :state="errors.length > 0 ? false:null"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                        <b-form-group
                                            label-for="Expiration Date"
                                        >
                                        <template v-slot:label>
                                            Expiration Date <span class="text-danger">*</span>
                                        </template>
                                            <validation-provider
                                                #default="{ errors }"
                                                name="Expiration Date"
                                                rules="required"
                                            >
                                                <flat-pickr
                                                    v-model="expirationDate"
                                                    class="form-control"
                                                    placeholder="Select a Date"
                                                    :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                                    :state="errors.length > 0 ? false:null"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-col>

                                <b-col md="8" class="px-1 mt-2">
                                    <h5 class="mb-0">
                                        Change password
                                    </h5>
                                    <hr>
                                </b-col>

                                <b-col md="8">
                                    <b-form-group
                                        label-for="password"
                                    >
                                        <template v-slot:label>
                                            Password <span class="text-danger">*</span>
                                        </template>
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Password"
                                            vid="Password"
                                            rules="password"
                                        >
                                            <b-form-input
                                                v-model="password"
                                                type="password"
                                                placeholder="Password"
                                                :state="errors.length > 0 ? false:null"
                                                required
                                                trim
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col md="8">
                                    <b-form-group
                                        label-for="password confirm"
                                    >
                                        <template v-slot:label>
                                            Confirm Password <span class="text-danger">*</span>
                                        </template>
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Confirm Password"
                                            :rules="password ? 'required|confirmed:Password' : 'confirmed:Password'"
                                        >
                                            <b-form-input
                                                v-model="passwordConfirm"
                                                :state="errors.length > 0 ? false:null"
                                                type="password"
                                                placeholder="Confirm Password"
                                                required
                                                trim
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col md="8">
                                    <!-- Form Actions -->
                                    <div class="d-flex mt-2 float-right">
                                        <b-button
                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                            variant="flat-primary"
                                            @click="backList"
                                        >
                                        Back to list
                                        </b-button>

                                        <b-button
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            variant="primary"
                                            class="mr-2"
                                            type="submit"
                                            @click.prevent="validationForm"
                                        >
                                        Edit
                                        </b-button>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </b-tab>
                <b-tab
                    :active="routeForReferral ? true : false"
                    title="Referral Details"
                    @click="changeStatusEdit('ref')"
                >
                    <edit-clinician-staff-referral-details :edit-staff-referral.sync="editStaffReferral" :idClinicianStaff="idClinicianStaff" />
                </b-tab>
                <b-tab
                    title="Tracking Details"
                    @click="changeStatusEdit('track')"
                    v-if="isAdmin"
                >
                    <edit-clinician-staff-tracking-details :edit-staff-tracking.sync="editStaffTracking" :idClinicianStaff="idClinicianStaff" />
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import { BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTable, BFormInvalidFeedback, BFormCheckbox, BAlert, BSpinner, VBTooltip, BPopover,BTab, BTabs, } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EditClinicianStaffReferralDetails from '../EditClinicianStaffReferralDetails.vue'
import EditClinicianStaffTrackingDetails from '../EditClinicianStaffTrackingDetails.vue'
import flatPickr from 'vue-flatpickr-component'
import { required, email } from '@validations'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            options: {
                block: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'false',
                    delimiter: ''
                },
            },
            userData: '',
            clinicianUsers: false,
            viewClinicianCLienActivity: false,
            clinicianOrClinicianViewer: false,
            staffOrClinician: false,
            dataSpecialist: false,
            adminOrManageUsers: false,
            isAdmin: false,
            loadDataPage: false,
            client: '',
            nameClient: '',
            requiredDischargeForm: false,
            popoverShow: false,
            popoverShowDischargeDate: false,
            isClinicianStaff: false,
            firstName: '',
            lastName: '',
            emailClinicianStaff: '',
            activeDate: '',
            expirationDate: '',
            password: '',
            passwordConfirm: '',

            // configuration remove record admission/discharge
            removeRecordAdmissionData: false,
            removeRecordDischargeData: false,

            // settings table and edit view
            statusEditCLinicianStaff: true,
            pushEditButton: false,
            itemsActivity: [],

            // form
            clinician: [],
            dateOfContact: null,
            methodOfContact: '',
            contactStatus: '',
            referredBy: '',
            otherReferredBy: '',
            warmTransfer: '',
            initialAppointmentDate: null,
            time: '',
            wasTheCallHelpful: '',
            wasTheCallHelpfulIfNotWhy: '',
            informationKitAccepted: '',
            informationKitOption: '',
            regionalCFEReceivingTheCall: '',
            borough: '',
            readmission: '',
            race: '',
            householdIncome: '',
            gamblingTypes: '',
            employmentStatus: '',
            educationLevels: '',

            ethnicity: '',
            veteran: '',
            zipCode: '',
            industryOfEmployment: '',
            maritalStatus: '',
            religiousPreference: '',
            connectedToPeerAdvocate: '',
            connectedToVSE: '',
            connectedToPeerEducationGroup: '',
            connectedToFamilyEducationGroup: '',
            connectedToAsianServicesProgram: '',
            // enter adminission/discharge information
            enterAdmissionDischargeInformation: false,
            enterAdmissionInformation: false,
            enterDischargeInformation: false,
            enterSurveyInformation: false,
            // admission form
            dateOfAdmission: null,
            newOrReturningClient: '',
            presentingProblemsAtAdmission: '',
            diagnosticCriteriaMetAtAdmission: '',
            otherProblemsAdmission: '',
            clientInvolvedWithLegalSystem: '',
            clientReleaseSigned: '',
            notesAdmission: '',
            // discharge form
            dateOfDischarge: null,
            dischargeStatus: '',
            plannedDischargeReasons: '',
            unplannedDischargeReason: '',
            numberOfSessionsAttended: null,
            diagnosticCriteriaMetAtAdmissionWasReduced: '',
            diagnosticCriteriaMetAtDischarge: '',
            goalsMet: '',
            currentGamblingComparedToAdmission: '',
            clientInvolvedWithSelfHelpAtDischarge: '',
            clientReferredForContinuedServices: '',
            ifSoWhatServicesWereRecommended: '',
            notesDischarge: '',
            // survey
            isClientInsured: '',
            isClientMatchedToaClinicianWhoAcceptsTheirInsurance: '',
            isTheClinicianBillingTheClientsInsurance: '',
            whyInsuranceWasNotBilled: '',
            whyInsuranceWasNotBilledOther: '',
            reasonWhyClientWasReferredToClinician: '',
            reasonWhyClientWasReferredToClinicianOther: '',

            // content right
            specialIssuesOrOtherComments: '',
            callNotes: '',
            activityStatus: '',
            closeNotes: '',
            json_data: [],

            // edit client activity
            // statusEdit: false,
            // idclinician: '',
            activityStatusAtomic: '',
            counterChangesActivityStatus: 0,

            // survey condition
            logicisClientInsured: false,
            logicisClientMatchedToaClinicianWhoAcceptsTheirInsurance: false,
            logicisTheClinicianBillingTheClientsInsurance: false,
            logicisTheClinicianBillingTheClientsInsuranceOptionYes: false,

            // date of dicharge listener
            dodListener: '',

            // active account 
            accountNotActive: true,
            accounActivetNotActive: true,
            usuarioDataExpiredDate: '',
            activeDateDB: null,
            routeForReferral: false,
            editStaffReferral: false,
            editStaffTracking: false,
            typeTabActive: 'det',
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTable,
        BFormInvalidFeedback,
        BFormCheckbox,
        BAlert,
        BSpinner,
        BPopover,
        BTab,
        BTabs,
        Cleave,
        
        // select
        vSelect,

        // date
        flatPickr,

        // Form Validation
        ValidationProvider,
        ValidationObserver,

        EditClinicianStaffReferralDetails,
        EditClinicianStaffTrackingDetails,
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    props: ['idClinicianStaff'],
    computed: {
        ...mapState(['usuario'])
    },
    watch: {
        idClinicianStaff(val) {
            // console.log(val)
            // if(val) {
                db.collection('usuarios').doc(val).get()
                .then(userDoc => {
                    // depends type of clinician role
                    // if(userDoc.data().role === 'clinician-staff') {
                        this.firstName = userDoc.data().firstName ? userDoc.data().firstName : ''
                        this.lastName = userDoc.data().lastName ? userDoc.data().lastName : ''
                        this.emailClinicianStaff = userDoc.data().email ? userDoc.data().email : ''
                        // active account
                        this.activeDate = userDoc.data().activeDateTrack ? userDoc.data().activeDateTrack : null
                        this.expirationDate = userDoc.data().expirationDateTrack ? userDoc.data().expirationDateTrack : null

                        this.loadDataPage = true
                    // } else {
                    //     this.loadDataPage = true
                    // }
                })
            // } else {
            //     this.loadDataPage = false
            // }
        },
        activeDate(val) {
            // console.log(val, this.activeDateDB)
            if(!this.expirationDate) {
                var formatDateOrigin = moment(val,'MM/DD/YYYY').format('MM/DD/YYYY')
                var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
                var expiredDate = moment(formatDate).add(12, 'months')
                var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('MM-DD-YYYY')
                this.expirationDate = dateUpdateFormat
                // console.log(this.expirationDate)
                this.usuarioDataExpiredDate = moment(expiredDate).format('x')


                var dateExpired = parseInt(this.usuarioDataExpiredDate, 10);
                var day1 = new Date(); 
                var day2 = new Date(dateExpired);
                var difference= day2-day1;
                var days = difference/(1000 * 3600 * 24);
                if(days > 0) {
                    this.accounActivetNotActive = true
                } else {
                    this.accounActivetNotActive = false
                }

            } else {
                var formatDateOrigin = moment(val,'MM/DD/YYYY').format('MM/DD/YYYY')
                var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
                var expiredDate = moment(formatDate).add(0, 's')

                if(this.activeDateDB !== val) {
                    var expiredDateReal = moment(formatDate).add(12, 'months')
                    var dateUpdateFormat = moment(expiredDateReal,'MM/DD/YYYY').format('MM-DD-YYYY')
                    this.expirationDate = dateUpdateFormat
                }
                // console.log(this.expirationDate)
                // var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('MM-DD-YYYY')
                
                // var dateUpdateFormat = moment(expiredDate).format('LLLL')
                this.usuarioDataExpiredDate = moment(expiredDate).format('x')
                // console.log(this.usuarioDataExpiredDate)
                var dateExpired = parseInt(this.usuarioDataExpiredDate, 10);
                var day1 = new Date(); 
                var day2 = new Date(dateExpired);
                var difference= day2-day1;
                var days = difference/(1000 * 3600 * 24);
                if(days > 0) {
                    this.accounActivetNotActive = true
                } else {
                    this.accounActivetNotActive = false
                }
            }

            // mark as not active
            if(val) {
                // console.log(val)
                var formatDateOrigin = moment(val,'MM/DD/YYYY').format('MM/DD/YYYY')
                var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
                var activeDate = moment(formatDate).add(0, 's')
                this.usuarioDataExpiredDate = moment(activeDate).format('x')

                var dateExpired = parseInt(this.usuarioDataExpiredDate, 10)
                var day1 = new Date(); 
                var day2 = new Date(dateExpired);
                var difference= day2-day1;
                var days = difference/(1000 * 3600 * 24);
                // console.log(days)
                if(days > 0) {
                    this.accounActivetNotActive = true
                } else {
                    this.accounActivetNotActive = false
                }
            }
        },
        expirationDate(val) {
            if(!val) {
                var formatDateOrigin = moment(val,'MM/DD/YYYY').format('MM/DD/YYYY')
                var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
                var expiredDate = moment(formatDate).add(0, 's')
                this.usuarioDataExpiredDate = moment(expiredDate).format('x')

                var dateExpired = parseInt(this.usuarioDataExpiredDate, 10);
                var day1 = new Date(); 
                var day2 = new Date(dateExpired);
                var difference= day2-day1;
                var days = difference/(1000 * 3600 * 24);
                if(Math.trunc(days) < 0) {
                    this.accountNotActive = true
                } else {
                    this.accountNotActive = false
                }
            } else {
                var formatDateOrigin = moment(val,'MM/DD/YYYY').format('MM/DD/YYYY')
                var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
                var expiredDate = moment(formatDate).add(0, 's')
                this.usuarioDataExpiredDate = moment(expiredDate).format('x')

                var dateExpired = parseInt(this.usuarioDataExpiredDate, 10)
                var day1 = new Date(); 
                var day2 = new Date(dateExpired);
                var difference= day2-day1;
                var days = difference/(1000 * 3600 * 24);
                if(Math.trunc(days) < 0) {
                    this.accountNotActive = true
                } else {
                    this.accountNotActive = false
                }
            }
        },
        editStaffReferral(val) {
            if(!val && this.typeTabActive === 'ref') {
                this.backList()
            }
        },
        editStaffTracking(val) {
            if(!val && this.typeTabActive === 'track') {
                this.backList()
            }
        }
    },
    methods: {
        ...mapActions(['updateClinicianDetails']),
        changeStatusEdit(val) {
            if(val === 'det') {
                this.typeTabActive = 'det'
                this.editStaffReferral = false
                this.editStaffTracking = false
            } else if(val === 'ref') {
                this.typeTabActive = 'ref'
                this.editStaffReferral = true
                this.editStaffTracking = false
            } else if(val === 'track') {
                this.typeTabActive = 'track'
                this.editStaffReferral = false
                this.editStaffTracking = true
            }
        },
        validationForm() {
            this.$refs.addClinicianRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    this.submitUsuario()
                }
            })
        },
        submitUsuario() {
            let objectUsuario = {
                id: this.idClinicianStaff,
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.emailClinicianStaff,
                role: 'clinician-staff'
            }

            // const user = auth.currentUser
            if(this.password && this.passwordConfirm) {
                var obj = {id: this.userData.uid, password: this.password}
                let dataBody = JSON.stringify({
                    "id": this.userData.uid,
                    "pass": obj.password
                })

                var config = {
                    'url': 'https://us-central1-nycpg-nycpgv.cloudfunctions.net/app/api/auth/update/usuarios/data',
                    'method': 'POST',
                    'timeout': 0,
                    'headers': {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    'data': dataBody
                }

                this.axios(config)
                .then(res => {
                    if(res.status === 200) {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Password changed.`,
                                icon: 'CoffeeIcon',
                                variant: 'success',
                            },
                        })
                        // this.cerrarSesion()
                    }
                })
                .catch(e => console.log(e.message))
            }

            if(this.userData.email !== this.emailClinicianStaff) {
                this.$http.get(`https://us-central1-nycpg-nycpgv.cloudfunctions.net/app/api/auth/update/usuarios/email/data/${this.userData.uid}/${this.userData.email}`)
                .then(res => {
                    if(res.status === 200) {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Email changed.`,
                                icon: 'CoffeeIcon',
                                variant: 'success',
                            },
                        })
                        // this.cerrarSesion()
                    }
                })
            }

            this.editClinicianTracking()
            this.updateClinicianDetails(objectUsuario)

            setTimeout(() => {
                this.backList()
            }, 400)
        },
        editClinicianTracking() {
            if(this.accountNotActive || this.accounActivetNotActive) {
                // console.log(this.usuarioDataExpiredDate)
                db.collection('usuarios').doc(this.idClinicianStaff).update({
                    expiredDate: this.usuarioDataExpiredDate
                })
            }

            if(!this.accounActivetNotActive && !this.accountNotActive) {
                db.collection('usuarios').doc(this.idClinicianStaff).update({
                    statusReferral: 'active'
                })
            } else {
                db.collection('usuarios').doc(this.idClinicianStaff).update({
                    statusReferral: 'inactive'
                })
            }

            db.collection('usuarios').doc(this.idClinicianStaff).update({
                activeDateTrack: this.activeDate,
                expirationDateTrack: this.expirationDate
            })

            var objTrack = [
                {text: '2 Day Training Completed Date', name: 'twoDaysTrainingCompletedDate', value: null},
                {text: 'Webinar CBT Completed Date', name: 'webinarCBTCompletedDate', value: null},
                {text: 'Webinar MI Completed Date', name: 'webinarMICompletedDate', value: null},
                {text: 'Collaborative Interview Completed Date', name: 'collaborativeInterviewCompletedDate', value: null},
                {text: 'MOU Signed Date', name: 'MOUSignedDate', value: null},
                {text: 'Malpractice Insurance Expiration Date', name: 'malpracticeInsuranceExpirationDate', value: null},
                {text: '60 Hour Course', name: 'sixtyHourCourse', value: null},
                {text: 'License Expiration Date', name: 'licenseExpirationDate', value: null},
                {text: 'Active Date', name: 'activeDate', value: this.activeDate},
                {text: 'Expiration Date', name: 'expirationDate', value: this.expirationDate},
                {text: 'Advanced Training Completed Date', name: 'advancedTrainingCompletedDate', value: null}
            ]

            db.collection('usuarios').doc(this.idClinicianStaff).collection('details').doc('trackingDetail').update({datePickers: objTrack})
        },
        backList() {
            this.statusEditCLinicianStaff = false
            this.$emit('statusEditCLinicianStaff',false)
        }
    },
    created() {
        this.isClinicianStaff = false
        this.routeForReferral = false
        this.editStaffReferral = false
        this.editStaffTracking = false
        this.typeTabActive = 'det'
        const user = auth.currentUser
        if(user) {
            db.collection('usuarios').doc(user.uid).get()
            .then(userDoc => {
                if(userDoc.data().role === 'admin') {
                    this.isAdmin = true
                    this.adminOrManageUsers = true
                } else if(userDoc.data().role === 'staff') {
                    this.staffOrClinician = true
                    if(userDoc.data().permissions.includes('manage users')) {
                        this.adminOrManageUsers = true
                    } else if(userDoc.data().permissions.includes('data specialist')) {
                        this.dataSpecialist = true
                    } else if(userDoc.data().permissions.includes('clinician viewer')) {
                        this.clinicianOrClinicianViewer = true
                    } else {
                        this.clinicianOrClinicianViewer = true
                    }
                } else if(userDoc.data().role === 'clinician' || userDoc.data().role === 'clinician-staff') {
                    this.staffOrClinician = true
                    this.clinicianUsers = true

                    this.isClinicianStaff = true
                }
            })
        }

        db.collection('usuarios').doc(this.idClinicianStaff).get()
        .then(userDoc => {
            // depends type of clinician role
            // if(userDoc.data().role === 'clinician-staff') {
                this.firstName = userDoc.data().firstName ? userDoc.data().firstName : ''
                this.lastName = userDoc.data().lastName ? userDoc.data().lastName : ''
                this.emailClinicianStaff = userDoc.data().email ? userDoc.data().email : ''
                // active account
                this.activeDate = userDoc.data().activeDateTrack ? userDoc.data().activeDateTrack : null
                this.expirationDate = userDoc.data().expirationDateTrack ? userDoc.data().expirationDateTrack : null

                this.userData = {
                    uid: userDoc.id,
                    email: userDoc.data().email,
                }

                this.loadDataPage = true
            // } else {
            //     this.loadDataPage = true
            // }
        })
    },
    mounted() {
        if(window.location.pathname.includes('users/edit/clinician')) {
            this.clinicianOrClinicianViewer = true
            this.viewClinicianCLienActivity = true
        }

        if(window.history && window.history.pushState) {
            window.history.pushState('forward', null, '');

            window.addEventListener('popstate', () => {
                // alert('Back button was pressed.');
                this.statusEditCLinicianStaff = false
                this.$emit('statusEditCLinicianStaff',false)
            });
        }

        this.pushEditButton = false
        this.$emit('statusEditCLinicianStaff',true)
    }
}
</script>

<style>
.cancelBtn-modal {
    border-radius: 13px !important;
}
.vs--disabled .vs__selected {
    color: #656b85 !important;
}
/* .btn-primary:hover:focus:active:visited {
    box-shadow: none !important;
}
.btn-primary:hover:focus:active:visited {
    box-shadow: none !important;
} */
.btn-primary:hover {
    box-shadow: none !important;
}
.btn-primary:focus {
    box-shadow: none !important;
}
.btn-primary:active {
    box-shadow: none !important;
}
.btn-primary:visited {
    box-shadow: none !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
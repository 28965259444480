<template>
    <div>
        <validation-observer ref="addClinicianRules">
            <b-form
                class="px-2"
            >
            <b-row class="mb-3">
            <b-col md="8">
                <!-- Profile -->
                <h4>Profile</h4>
                <hr>
                <b-row>
                    <b-col md="6">
                        <!-- First Name -->
                        <b-form-group
                            label-for="First name"
                        >
                        <template v-slot:label>
                            First name <span class="text-danger">*</span>
                        </template>
                            <validation-provider
                                #default="{ errors }"
                                name="First name"
                                rules="required"
                            >
                                <b-form-input
                                    id="full-name"
                                    v-model="firstName"
                                    trim
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="First name"
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="6">
                        <!-- Last Name -->
                        <b-form-group
                            label-for="Last name"
                        >
                        <template v-slot:label>
                            Last name <span class="text-danger">*</span>
                        </template>
                            <validation-provider
                                #default="{ errors }"
                                name="Last name"
                                rules="required"
                            >
                                <b-form-input
                                    id="full-name"
                                    v-model="lastName"
                                    trim
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Last name"
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
                
                <b-col md="6" class="pl-0">
                    <!-- Agency -->
                    <b-form-group
                        label="Agency"
                        label-for="agency"
                    >
                    <!-- <template v-slot:label>
                        Agency <span class="text-danger">*</span>
                    </template> -->
                        <!-- <validation-provider
                            #default="{ errors }"
                            name="Agency"
                            rules="required"
                        > -->
                            <b-form-input
                                v-model="agency"
                                trim
                                placeholder="Agency"
                                :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                            />
                            <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                        <!-- </validation-provider> -->
                    </b-form-group>
                </b-col>

                <b-col md="6" class="pl-0">
                    <!-- Email -->
                    <b-form-group
                        label-for="email"
                    >
                    <template v-slot:label>
                        Email <span class="text-danger">*</span>
                    </template>
                        <validation-provider
                            #default="{ errors }"
                            name="Email"
                            rules="required|email"
                        >
                            <b-form-input
                                id="email"
                                v-model="email"
                                :state="errors.length > 0 ? false:null"
                                trim
                                placeholder="Email"
                                :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <b-col md="6" class="pl-0">
                    <!-- County of Residence -->
                    <validation-provider
                        #default="{ errors }"
                        name="County"
                        rules="required"
                    >
                        <b-form-group
                            label-for="County"
                            :state="errors.length > 0 ? false:null"
                        >
                        <template v-slot:label>
                            County <span class="text-danger">*</span>
                        </template>
                            <v-select
                                v-model="countryOfResidence"
                                placeholder="County"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="optionsCountryOfResidence"
                                :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                
                <b-row>
                    <b-col md="6">
                        <!-- Region -->
                        <validation-provider
                            #default="{ errors }"
                            name="Region"
                            rules="required"
                        >
                            <b-form-group
                                label-for="region"
                                :state="errors.length > 0 ? false:null"
                            >
                            <template v-slot:label>
                                Region <span class="text-danger">*</span>
                            </template>
                                <v-select
                                    v-model="region"
                                    placeholder="Region"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="optionsRegion"
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6" v-if="region === 'NYC'">
                        <!-- Borough -->
                        <validation-provider
                            #default="{ errors }"
                            name="Borough"
                            rules="required"
                        >
                            <b-form-group
                                label-for="borough"
                                :state="errors.length > 0 ? false:null"
                            >
                            <template v-slot:label>
                                Borough <span class="text-danger">*</span>
                            </template>
                                <v-select
                                    v-model="borough"
                                    placeholder="Borough"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="optionsBorough"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="6">
                        <!-- Program -->
                        <validation-provider
                            #default="{ errors }"
                            name="Program"
                            rules="required"
                        >
                            <b-form-group
                                label-for="program"
                                :state="errors.length > 0 ? false:null"
                            >
                            <template v-slot:label>
                                Program <span class="text-danger">*</span>
                            </template>
                                <v-select
                                    v-model="program"
                                    placeholder="Program"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="optionsProgram"
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="program === 'OASAS'">
                        <!-- Program Type -->
                        <validation-provider
                            #default="{ errors }"
                            name="Program Type"
                            rules="required"
                        >
                            <b-form-group
                                label-for="program-type"
                                :state="errors.length > 0 ? false:null"
                            >
                            <template v-slot:label>
                                Program Type <span class="text-danger">*</span>
                            </template>
                                <v-select
                                    v-model="programType"
                                    placeholder="Program Type"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="optionsProgramType"
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>
                
                <!-- Details -->
                <h4 class="mt-2">Details</h4>
                <hr>
                <b-row cols="12">
                    <b-col md="6">
                        <!-- License type -->
                        <b-form-group
                            label="License Type"
                            label-for="licenseType"
                        >
                            <v-select
                                v-model="licenseType"
                                placeholder="License type"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="optionsLicenseType"
                                multiple
                                :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <!-- Licese number -->
                        <b-form-group
                            label="License Number"
                            label-for="License Number"
                        >
                            <b-form-input
                                v-model="licenseNumber"
                                trim
                                placeholder="License number"
                                :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                
                <b-row cols="12">
                    <b-col md="6">
                        <!-- Insurance types accepted -->
                        <b-form-group
                            label="Insurance types accepted"
                            label-for="Insurance types accepted"
                        >
                            <v-select
                                v-model="insuranceTypesAccepted"
                                placeholder="Insurance types accepted"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="optionsInsuranceTypesAccepted"
                                multiple
                                :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                            />
                        </b-form-group>
                    </b-col>
                    <!-- <b-col md="6" v-if="program === 'PGRC - Private Practitioner' || program === 'PGRC - Group Practice'">
                        <validation-provider
                            #default="{ errors }"
                            name="Accepting new clients"
                            rules="required"
                        >
                            <b-form-group
                                label-for="Accepting new clients"
                                :state="errors.length > 0 ? false:null"
                            >
                            <template v-slot:label>
                                Accepting new clients <span class="text-danger">*</span>
                            </template>
                                <v-select
                                    v-model="acceptingNewClients"
                                    placeholder="Accepting new clients"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="optionsYesNo"
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col> -->
                </b-row>

                <b-row cols="12">
                    <b-col md="6">
                        <!-- Specialities -->
                        <b-form-group
                            label="Specialities"
                            label-for="Specialities"
                        >
                            <v-select
                                v-model="specialities"
                                placeholder="Specialities"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="optionsSpecialities"
                                multiple
                                :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                            />
                        </b-form-group>
                    </b-col>
                    <!-- <b-col md="6" v-if="program === 'PGRC - Private Practitioner' || program === 'PGRC - Group Practice'">
                        <validation-provider
                            #default="{ errors }"
                            name="Accepting referrals outside of catchment"
                            rules="required"
                        >
                            <b-form-group
                                label-for="Accepting referrals outside of catchment"
                                :state="errors.length > 0 ? false:null"
                            >
                            <template v-slot:label>
                                Accepting referrals outside of catchment <span class="text-danger">*</span>
                            </template>
                                <v-select
                                    v-model="acceptingReferralsOutsideOfCatchment"
                                    placeholder="Accepting referrals outside of catchment"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="optionsYesNo"
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col> -->
                </b-row>

                <b-col md="6" class="pl-0">
                    <!-- Languages -->
                    <b-form-group
                        label="Languages"
                        label-for="Languages"
                    >
                        <v-select
                            v-model="languages"
                            placeholder="Languages"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="optionsLanguages"
                            multiple
                            :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                        />
                    </b-form-group>
                </b-col>

                <b-col md="6" class="pl-0">
                    <!-- Mailing address -->
                    <b-form-group
                        label="Mailing address"
                        label-for="Mailing address"
                    >
                        <b-form-input
                            v-model="mailingAddress"
                            trim
                            placeholder="Mailing address"
                            :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                        />
                    </b-form-group>
                </b-col>

                <b-row cols="12">
                    <b-col md="4">
                        <!-- City -->
                        <b-form-group
                            label="City"
                            label-for="City"
                        >
                            <b-form-input
                                v-model="city"
                                trim
                                placeholder="City"
                                :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <!-- State -->
                        <b-form-group
                            label="State"
                            label-for="State"
                        >
                            <b-form-input
                                v-model="state"
                                trim
                                placeholder="State"
                                :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <!-- zip -->
                        <b-form-group
                            label="ZIP"
                            label-for="ZIP"
                        >
                            <b-form-input
                                v-model="zip"
                                trim
                                placeholder="ZIP"
                                :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-col md="6" class="pl-0">
                    <!-- Phone -->
                    <b-form-group
                        label="Phone"
                        label-for="phone"
                    >
                        <b-form-input
                            id="phone"
                            v-model="contact"
                            trim
                            placeholder="Phone"
                            :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                        />
                    </b-form-group>
                </b-col>

                <!-- Attributes -->
                <!-- <h4 class="mt-2">Attributes</h4>
                <hr>
                <b-col md="6" class="pl-0"> -->
                    <!-- Attributes -->
                    <!-- <b-form-group
                        label="Attributes"
                        label-for="Attributes"
                    >
                        <v-select
                            v-model="attributes"
                            placeholder="Attributes"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="optionsAttributes"
                        />
                    </b-form-group>
                </b-col> -->

                <!-- Notes -->
                <h4 class="mt-2">Notes</h4>
                <hr>
                <b-col md="6" class="pl-0">
                    <!-- Notes -->
                    <b-form-group
                        label="Notes"
                        label-for="notes"
                    >
                        <b-form-textarea
                            v-model="notes"
                            placeholder="Notes"
                            rows="3"
                            :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                        />
                    </b-form-group>
                </b-col>
            </b-col>


            <b-col md="4">
                <!-- Attributes -->
                <h4>Attributes</h4>
                <hr>
                <b-form-checkbox
                    v-model="providesRemoteAndOrOffSiteSessionsIfRequired"
                    :value="true"
                    :disabled="dataSpecialist ? true : false"
                >
                Provides remote and/or off-site sessions if required
                </b-form-checkbox>
            

                <!-- Password -->
                <div v-if="dataSpecialist || adminOrManageUsers">
                    <h4 class="mt-2">Change password</h4>
                    <hr>
                    <b-row class="pl-0">
                        <!-- password -->
                        <b-col cols="12">
                            <b-form-group
                                label="Password"
                                label-for="a-password"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Password"
                                    vid="Password"
                                    rules="min:8|password"
                                >
                                <b-form-input
                                    id="a-password"
                                    v-model="passValue"
                                    :state="errors.length > 0 ? false:null"
                                    type="password"
                                    placeholder="Password"
                                    :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- confirm password -->
                        <b-col cols="12">
                            <b-form-group
                                label="Confirm Password"
                                label-for="ac-password"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Confirm Password"
                                    :rules="passValue ? 'required|confirmed:Password' : 'confirmed:Password'"
                                >
                                    <b-form-input
                                        id="ac-password"
                                        v-model="passConfirm"
                                        :state="errors.length > 0 ? false:null"
                                        type="password"
                                        placeholder="Confirm Password"
                                        :disabled="clinicianOrClinicianViewer || dataSpecialist ? true : false"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </b-col>

                <!-- Form Actions -->
                <!-- <div class="d-flex mt-2 float-right w-100 mt-0" v-if="!clinicianOrClinicianViewer"> -->
                    <b-col md="10" v-if="!clinicianOrClinicianViewer && !dataSpecialist"></b-col>
                    <b-col md="2" v-if="!clinicianOrClinicianViewer && !dataSpecialist">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                            @click.prevent="validationForm"
                        >
                        Save
                        </b-button>
                    </b-col>
                    <!-- <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        style="position: absolute; right: 0;"
                        variant="primary"
                        class="mr-2"
                        type="submit"
                        @click.prevent="validationForm"
                    >
                    Save
                    </b-button>
                </div> -->
            </b-row>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BTabs, BTab, BCardText, BCard, BCol, BRow, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, email, min, confirmed } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { mapActions } from 'vuex'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            min,
            confirmed,
            countries,
            userData: '',
            idClinicianDependency: '',

            // profile
            firstName: '',
            lastName: '',
            agency: '',
            email: '',
            countryOfResidence: '',
            optionsCountryOfResidence: [],
            region: '',
            optionsRegion: [],
            borough: '',
            optionsBorough: [
                'Bronx',
                'Brooklyn',
                'Manhattan',
                'Queens',
                'Staten Island'
            ],
            program: '',
            optionsProgram: [],

            // program OASAS
            programType: '',
            optionsProgramType: [
                'Outpatient Treatment Services',
                'ATCs (OASAS run)',
                'Chemical Dependency Youth',
                'Crisis Services',
                'Inpatient Treatment Services',
                'Methadone Treatment',
                'Residential Treatment Services'
            ],

            // details
            licenseType: '',
            optionsLicenseType: [],
            licenseNumber: '',
            insuranceTypesAccepted: '',
            optionsInsuranceTypesAccepted: [],
            specialities: '',
            optionsSpecialities: [],
            languages: '',
            optionsLanguages: [],
            mailingAddress: '',
            city: '',
            state: '',
            zip: '',
            contact: '',
            acceptingNewClients: '',
            acceptingReferralsOutsideOfCatchment: '',
            optionsYesNo: [
                'Yes',
                'No'
            ],

            // password
            passValue: '',
            passConfirm: '',

            // Attributes
            attributes: '',
            optionsAttributes: [],
            providesRemoteAndOrOffSiteSessionsIfRequired: false,

            // notes
            notes: '',

            // change email
            emailOld: '',
            
            rules: {
                required: v => !!v || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                email: v => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(v) || 'Email not valid.'
                },
            },
            statusReferral: '',
            clinicianOrClinicianViewer: false,
            clinicianUsers: false,
            dataSpecialist: false,
            adminOrManageUsers: false,
            loadDataPage: false,
        }
    },
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BCardText,
        BTabs,
        BTab,
        BCard,
        BCol,
        BRow,
        BFormTextarea,
        BFormCheckbox,
        vSelect,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    methods: {
        ...mapActions(['updateClinicianDetails']),
        validationForm() {
            this.$refs.addClinicianRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    this.submitUsuario()
                }
            })
        },
        submitUsuario() {
            // change password
            // passValue: '',
            // passConfirm: '',

            if(this.email !== this.emailOld) {
                this.$http.get(`https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/auth/update/usuarios/email/data/${this.idClinicianDependency}/${this.email}`)
                .then(res => { 
                    // console.log(res.data, res.status)
                    if(res.status === 200) {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Email changed.`,
                                icon: 'CoffeeIcon',
                                variant: 'success',
                            },
                        })
                    }
                })
            }

            if(this.passValue && this.passConfirm) {
                let dataBody = JSON.stringify({
                    "id": this.idClinicianDependency,
                    "pass": this.passValue
                });
                var config = {
                    'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/auth/update/usuarios/data',
                    'method': 'POST',
                    'timeout': 0,
                    'headers': {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    'data': dataBody
                }

                this.axios(config)
                .then(res => {
                    if(res.status === 200) {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Password changed.`,
                                icon: 'CoffeeIcon',
                                variant: 'success',
                            },
                        })
                    }
                })
                .catch(e => console.log(e.message))

                // this.$http.get(`https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/auth/update/usuarios/data/${this.$route.params.id}/${this.passValue}`)
                // .then(res => { 
                //     // console.log(res.data, res.status)
                //     if(res.status === 200) {
                //         this.$toast({
                //             component: ToastificationContent,
                //             position: 'top-right',
                //             props: {
                //                 title: `Password changed.`,
                //                 icon: 'CoffeeIcon',
                //                 variant: 'success',
                //             },
                //         })
                //     }
                // })
            }

            let objectUsuario = {
                id: this.idClinicianDependency,
                firstName: this.firstName,
                lastName: this.lastName,
                agency: this.agency,
                email: this.email,
                countryOfResidence: this.countryOfResidence,
                region: this.region,
                borough: this.borough,
                program: this.program,
                programType: this.programType,

                // details
                licenseType: this.licenseType,
                licenseNumber: this.licenseNumber,
                insuranceTypesAccepted: this.insuranceTypesAccepted,
                specialities: this.specialities,
                // acceptingNewClients: this.acceptingNewClients,
                // acceptingReferralsOutsideOfCatchment: this.acceptingReferralsOutsideOfCatchment,
                languages: this.languages,
                mailingAddress: this.mailingAddress,
                city: this.city,
                state: this.state,
                zip: this.zip,
                contact: this.contact,

                // Attributes
                attributes: this.attributes,
                providesRemoteAndOrOffSiteSessionsIfRequired: this.providesRemoteAndOrOffSiteSessionsIfRequired,
                
                // notes
                notes: this.notes,
                role: 'clinician',
            }
            this.updateClinicianDetails(objectUsuario)
            // this.firstName = ''
            // this.lastName = ''
            // this.agency = ''
            // this.email = ''
            // this.countryOfResidence = ''
            // this.region = ''
            // this.program = ''
            

            // details
            // this.licenseType = ''
            // this.licenseNumber = ''
            // this.insuranceTypesAccepted = ''
            // this.specialities = ''
            // this.languages = ''
            // this.mailingAddress = ''
            // this.city = ''
            // this.state = ''
            // this.zip = ''
            // this.contact = ''

            // Attributes
            // this.attributes = ''
            // this.providesRemoteAndOrOffSiteSessionsIfRequired = false
        }
    },
    created() {
        const user = auth.currentUser
        if(user) {
            db.collection('usuarios').doc(user.uid).get()
            .then(userDoc => {
                if(userDoc.data().role === 'admin') {
                    this.adminOrManageUsers = true
                } else if(userDoc.data().role === 'staff') {
                    if(userDoc.data().permissions.includes('manage users')) {
                        this.adminOrManageUsers = true
                    } else if(userDoc.data().permissions.includes('data specialist')) {
                        this.dataSpecialist = true
                    } else if(userDoc.data().permissions.includes('clinician viewer')) {
                        this.clinicianOrClinicianViewer = true
                    } else {
                        this.clinicianOrClinicianViewer = true
                    }
                } else if(userDoc.data().role === 'clinician') {
                    this.clinicianUsers = true
                }

                if(userDoc.data().role === 'clinician-staff') {
                    this.idClinicianDependency = userDoc.data().dependency
                    // this.idClinicianDependency = user.uid
                    db.collection('usuarios').doc(userDoc.data().dependency)
                    // db.collection('usuarios').doc(user.uid)
                    .onSnapshot(doc => {
                        this.emailOld = doc.data().email ? doc.data().email : ''

                        this.firstName = doc.data().firstName ? doc.data().firstName : ''
                        this.lastName = doc.data().lastName ? doc.data().lastName : ''
                        this.agency = doc.data().agency ? doc.data().agency : ''
                        this.email = doc.data().email ? doc.data().email : ''
                        this.countryOfResidence = doc.data().countryOfResidence ? doc.data().countryOfResidence : ''
                        this.region = doc.data().region ? doc.data().region : ''
                        this.borough = doc.data().borough ? doc.data().borough : ''
                        this.program = doc.data().program ? doc.data().program : ''
                        this.programType = doc.data().programType ? doc.data().programType : ''
                        
                        // details
                        this.licenseType = doc.data().licenseType ? doc.data().licenseType : ''
                        this.licenseNumber = doc.data().licenseNumber ? doc.data().licenseNumber : ''
                        this.insuranceTypesAccepted = doc.data().insuranceTypesAccepted ? doc.data().insuranceTypesAccepted : ''
                        this.specialities = doc.data().specialities ? doc.data().specialities : ''
                        this.acceptingNewClients = doc.data().acceptingNewClients ? doc.data().acceptingNewClients : ''
                        this.acceptingReferralsOutsideOfCatchment = doc.data().acceptingReferralsOutsideOfCatchment ? doc.data().acceptingReferralsOutsideOfCatchment : ''
                        this.languages = doc.data().languages ? doc.data().languages : ''
                        this.mailingAddress = doc.data().mailingAddress ? doc.data().mailingAddress : ''
                        this.city = doc.data().city ? doc.data().city : ''
                        this.state = doc.data().state ? doc.data().state : ''
                        this.zip = doc.data().zip ? doc.data().zip : ''
                        this.contact = doc.data().contact ? doc.data().contact : ''

                        // Attributes
                        this.attributes = doc.data().attributes ? doc.data().attributes : ''
                        this.providesRemoteAndOrOffSiteSessionsIfRequired = doc.data().providesRemoteAndOrOffSiteSessionsIfRequired ? doc.data().providesRemoteAndOrOffSiteSessionsIfRequired : false

                        // notes
                        this.notes = doc.data().notes ? doc.data().notes : ''

                        // status
                        this.statusReferral = doc.data().statusReferral ? doc.data().statusReferral : ''
                    })
                } else {
                    this.idClinicianDependency = this.$route.params.id
                    db.collection('usuarios').doc(this.$route.params.id)
                    .onSnapshot(doc => {
                        this.emailOld = doc.data().email ? doc.data().email : ''

                        this.firstName = doc.data().firstName ? doc.data().firstName : ''
                        this.lastName = doc.data().lastName ? doc.data().lastName : ''
                        this.agency = doc.data().agency ? doc.data().agency : ''
                        this.email = doc.data().email ? doc.data().email : ''
                        this.countryOfResidence = doc.data().countryOfResidence ? doc.data().countryOfResidence : ''
                        this.region = doc.data().region ? doc.data().region : ''
                        this.borough = doc.data().borough ? doc.data().borough : ''
                        this.program = doc.data().program ? doc.data().program : ''
                        this.programType = doc.data().programType ? doc.data().programType : ''
                        
                        // details
                        this.licenseType = doc.data().licenseType ? doc.data().licenseType : ''
                        this.licenseNumber = doc.data().licenseNumber ? doc.data().licenseNumber : ''
                        this.insuranceTypesAccepted = doc.data().insuranceTypesAccepted ? doc.data().insuranceTypesAccepted : ''
                        this.specialities = doc.data().specialities ? doc.data().specialities : ''
                        this.acceptingNewClients = doc.data().acceptingNewClients ? doc.data().acceptingNewClients : ''
                        this.acceptingReferralsOutsideOfCatchment = doc.data().acceptingReferralsOutsideOfCatchment ? doc.data().acceptingReferralsOutsideOfCatchment : ''
                        this.languages = doc.data().languages ? doc.data().languages : ''
                        this.mailingAddress = doc.data().mailingAddress ? doc.data().mailingAddress : ''
                        this.city = doc.data().city ? doc.data().city : ''
                        this.state = doc.data().state ? doc.data().state : ''
                        this.zip = doc.data().zip ? doc.data().zip : ''
                        this.contact = doc.data().contact ? doc.data().contact : ''

                        // Attributes
                        this.attributes = doc.data().attributes ? doc.data().attributes : ''
                        this.providesRemoteAndOrOffSiteSessionsIfRequired = doc.data().providesRemoteAndOrOffSiteSessionsIfRequired ? doc.data().providesRemoteAndOrOffSiteSessionsIfRequired : false

                        // notes
                        this.notes = doc.data().notes ? doc.data().notes : ''

                        // status
                        this.statusReferral = doc.data().statusReferral ? doc.data().statusReferral : ''
                    })
                }
                
                this.userData = userDoc.data()
                this.loadDataPage = true
            })
        }

        db.collection('taxonomies').doc('clinician').get()
        .then(docTaxonomia => {
            // profile fields
            this.optionsRegion = docTaxonomia.data().region
            this.optionsProgram = docTaxonomia.data().program
            this.optionsAttributes = docTaxonomia.data().clinicianAttributes

            // clinician details fields
            this.optionsLicenseType = docTaxonomia.data().licenseType
            this.optionsInsuranceTypesAccepted = docTaxonomia.data().insuranceTypesAcepted
            this.optionsSpecialities = docTaxonomia.data().specialities
            this.optionsLanguages = docTaxonomia.data().languages

            // tracking details fields
            // this.regionalCFEAssigned = docTaxonomia.data().regionalCFEAssigned
        })

        db.collection('taxonomies').doc('client').get()
        .then(docTaxonomia => {
            this.optionsCountryOfResidence = docTaxonomia.data().countryOfResidence
        })
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>